import { all, takeEvery, takeLatest, put, call, select } from "redux-saga/effects";
import api from "api";
import _ from "lodash";
import actions from "./actions";
import { streamsInclude } from "./includes";

const COLLECTION_NAME = "stream"; // change your collection
const COLLECTION_COURSE_NAME = "course"; // change your collection
const COLLECTION_EXERCISE_NAME = "exercise"; // change your collection
const COLLECTION_USERS_NAME = "user"; // change your collection

const getPage = (state) => state.stream.page;
const getItemsPerPage = (state) => state.stream.itemsPerPage;
const getOrderBy = (state) => state.stream.orderBy;
const getOrderByDirection = (state) => state.stream.orderByDirection;
const getFilters = (state) => state.stream.filters;

function* loadFromApi({ payload }) {
  try {
    const page = yield select(getPage);
    const itemsPerPage = yield select(getItemsPerPage);
    const orderBy = yield select(getOrderBy);
    const orderByDirection = yield select(getOrderByDirection);
    const filters = yield select(getFilters);

    const params = {
      query: {
				isCancelled: false,
        $select: ["id", "courseId", "num", "name"],
        $or: [
          {
            name: {
              $iLike: `%${filters.search}%`,
            },
          },
        ],
        $limit: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $sort: {
          [orderBy]: orderByDirection,
        },
        include: streamsInclude(),
      },
    };

    const data = yield call(() => api.service(COLLECTION_NAME).find(params));

    yield put(actions.loadFromApiSuccess(data.data, data.total));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadCoursesFromApi() {
  try {
    const page = 1;
    const itemsPerPage = 100;
    const orderBy = "name";
    const orderByDirection = 1;
    const filters = {
      search: "",
    };

    const params = {
      query: {
        $select: ["id", "name"],
        $or: [
          {
            name: {
              $iLike: `%${filters.search}%`,
            },
          },
          {
            description: {
              $iLike: `%${filters.search}%`,
            },
          },
        ],
        $limit: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $sort: {
          [orderBy]: orderByDirection,
        },
      },
    };

    const data = yield call(() =>
      api.service(COLLECTION_COURSE_NAME).find(params)
    );

    yield put(actions.loadCoursesFromApiSuccess(data.data, data.total));
  } catch (error) {
    console.log(error);
    yield put(actions.loadCoursesFromApiError(error));
  }
}

function* loadExercisesFromApi({ payload }) {
  try {
    const page = 1;
    const itemsPerPage = 100;
    const orderBy = "orderBy";
    const orderByDirection = 1;
    const filters = {
      search: "",
    };

    const params = {
      query: {
        courseId: payload.id,
        $select: ["id", "courseId", "name", "duration"],
        $limit: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $sort: {
          [orderBy]: orderByDirection,
        },
      },
    };

    const data = yield call(() =>
      api.service(COLLECTION_EXERCISE_NAME).find(params)
    );

    yield put(actions.loadExercisesFromApiSuccess(data.data, data.total));
  } catch (error) {
    console.log(error);
    yield put(actions.loadExercisesFromApiError(error));
  }
}

function* loadUsersFromApi() {
  try {
    const params = {
      query: {
        role: "teacher",
        $select: ["id", "key", "firstname", "lastname"],
      },
    };

    const data = yield call(() =>
      api.service(COLLECTION_USERS_NAME).find(params)
    );

    yield put(actions.loadUsersFromApiSuccess(data.data));
  } catch (error) {
    console.log(error);
  }
}

function* removeFromApi({ payload }) {
  try {
    const data = yield call(() =>
      api.service(COLLECTION_NAME).remove(payload.id)
    );
    console.log(data);

    yield put(actions.removeFromApiSuccess(data));
  } catch (error) {
    console.log("REMOVE CALL ERROR");

    console.log(error);
    yield put(actions.removeFromApiError(error));
  }
}

function* cancelFromApi({ payload }) {
  try {
		const stream = yield call(() => api.service(COLLECTION_NAME).get(payload.id));

		const course = yield call(() => api.service(COLLECTION_COURSE_NAME).get(stream.courseId));
		
    const data = yield call(() =>
      api.service(COLLECTION_NAME).patch(payload.id, { isCancelled: true, courseName: course.name })
    );

    yield put(actions.cancelFromApiSuccess(data));
  } catch (error) {
    console.log("CANCEL CALL ERROR");

    console.log(error);
    yield put(actions.cancelFromApiError(error));
  }
}

function* saveLessonToApi({ payload }) {
  const { item } = payload;

  try {
    const lessonParams = {
      teacherId: item?.userId,
      streamId: item?.streamId,
      startAt: item?.startTime ? item?.startTime : item?.startAt,
      finishAt: item?.finishTime,
      exerciseId: item?.exerciseId,
      isIndividual: item?.isIndividual,
    };

    let itemResponse = {};

    if (item.id) {
      itemResponse = yield call(() =>
        api.service("lesson").patch(item.id, lessonParams)
      );
    } else {
      itemResponse = yield call(() =>
        api.service("lesson").create(lessonParams)
      );
    }

    yield put(actions.saveLessonToApiSuccess(itemResponse));
  } catch (error) {
    console.log(error);
  }
}

function* removeLessonFromApi({ payload }) {
  const { id } = payload;
  
  try {
    let itemResponse = yield call(() =>
      api.service("lesson").remove(id)
    );

    yield put(actions.removeLessonFromApiSuccess(itemResponse));
  } catch (error) {
    console.log(error);
    yield put(actions.removeLessonFromApiError(error));
  }
}

function* saveManyLessonsToApi({ payload }) {
  const { item } = payload;

  try {
    const itemResponse = yield all(
      item.map((el) =>
        call(() =>
          api.service("lesson").create({
            teacherId: el.userId,
            streamId: el.streamId,
            startAt: el.startTime,
            exerciseId: el.exerciseId,
          })
        )
      )
    );

    yield put(actions.saveManyLessonToApiSuccess(itemResponse));
  } catch (error) {
    console.log(error);
  }
}

function* saveToApi({ payload }) {
  const { item, subscriptions } = payload;

  try {
    const params = {
      name: item.name,
      usersLimit: item.usersLimit,
      isFirstFree: item.isFirstFree,
      canBuySingle: item.canBuySingle,
      isPreliminary: item.isPreliminary,
      isPartSubscribtion: item.isPartSubscribtion,
      isSecondPartSubscribtion: item.isSecondPartSubscribtion,
      isClosed: item.isClosed,
      personalPrice: item.personalPrice,
      courseId: item.courseId,
    };

    let itemResponse = {};
    let subscriptionItemResponse = {};

    if (item.id) {
      itemResponse = yield call(() =>
        api.service(COLLECTION_NAME).patch(item.id, params)
      );

      subscriptionItemResponse = yield all(
        _.map(
					_.compact(subscriptions)
						.filter((subscription) => isFinite(subscription.exerciseCount)
				), (subscription) => {
          if (subscription.id) {
            return call(() =>
              api.service("subscription").patch(subscription.id, {
                cost: subscription?.cost || 0,
                exerciseCount: subscription?.exerciseCount,
                streamId: item.id,
                isActive: subscription?.isActive,
                isFree: subscription?.isFree,
                isIndividual: subscription?.isIndividual,
                isFull: subscription?.isFull,
                isCustom: subscription?.isCustom,
              })
            );
          } else {
            return !_.isEmpty(subscription) && call(() =>
              api.service("subscription").create({
                cost: subscription?.cost || 0,
                exerciseCount: subscription?.exerciseCount,
                streamId: item.id,
                isActive: subscription?.isActive,
                isFree: subscription?.isFree,
                isIndividual: subscription?.isIndividual,
                isFull: subscription?.isFull,
                isCustom: subscription?.isCustom,
              })
            ) || {};
          }
        })
      );
    } else {
      itemResponse = yield call(() =>
        api.service(COLLECTION_NAME).create(params)
      );

      if (itemResponse.id) {
        subscriptionItemResponse = yield all(
          _.map(
						_.compact(subscriptions)
							.filter((subscription) => isFinite(subscription.exerciseCount),
					), (subscription) => {
            return !_.isEmpty(subscription) && call(() =>
              api.service("subscription").create({
                cost: subscription.cost || 0,
                exerciseCount: subscription.exerciseCount,
                streamId: itemResponse.id,
                isActive: subscription?.isActive,
                isFree: subscription?.isFree,
                isIndividual: subscription?.isIndividual,
                isFull: subscription?.isFull,
                isCustom: subscription?.isCustom,
              })
            ) || {};
          })
        );
      }
    }

    yield put(actions.saveToApiSuccess(itemResponse, subscriptionItemResponse));
    yield put(actions.loadFromApi(item.courseId));
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),
    takeEvery(actions.LOAD_COURSES_FROM_API, loadCoursesFromApi),
    takeEvery(actions.LOAD_EXERCISES_FROM_API, loadExercisesFromApi),
    takeEvery(actions.LOAD_USERS_FROM_API, loadUsersFromApi),
    takeEvery(actions.SET_PAGE, loadFromApi),
    takeEvery(actions.SET_ITEMS_PER_PAGE, loadFromApi),
    takeEvery(actions.SET_ORDER_BY, loadFromApi),
    takeEvery(actions.SET_ORDER_BY_DIRECTION, loadFromApi),
    takeEvery(actions.SET_FILTER, loadFromApi),
    takeEvery(actions.SAVE_TO_API, saveToApi),
    takeEvery(actions.SAVE_LESSON_TO_API, saveLessonToApi),
    takeEvery(actions.SAVE_MANY_LESSONS_TO_API, saveManyLessonsToApi),
    // takeEvery(actions.SAVE_SUBSCRIPTION_TO_API, saveSubscriptionToApi),
    takeLatest(actions.CANCEL_FROM_API, cancelFromApi),
    takeLatest(actions.REMOVE_FROM_API, removeFromApi),
    takeEvery(actions.REMOVE_LESSON_FROM_API, removeLessonFromApi),
  ]);
}
