import actions from './actions';

const initState = {
  isLoading: false,
  error: null,
  lessons: [],
  page: 1,
  orderBy: 'id',
  orderByDirection: 1,
  filters: {
    search: '%',
  },
  totalItems: 0,
  itemsPerPage: 8,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_API:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case actions.LOAD_FROM_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lessons: payload.data,
        totalItems: payload.totalItems,
        error: false,
      };
    case actions.LOAD_FROM_API_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case actions.REMOVE_FROM_API:
      return {
        ...state,
        error: false,
      };
    case actions.REMOVE_FROM_API_SUCCESS:
      return {
        ...state,
        error: false,
      };
    case actions.REMOVE_FROM_API_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case actions.SAVE_TO_API:
      return {
        ...state,
        error: false,
      };
    case actions.SAVE_TO_API_SUCCESS:
      return {
        ...state,
        error: false,
      };
    case actions.SAVE_TO_API_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case actions.SET_PAGE:
      return {
        ...state,
        page: payload.page,
      };
    case actions.SET_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: payload.itemsPerPage,
        page: 1,
      };
    case actions.SET_ORDER_BY:
      return {
        ...state,
        orderBy: payload.orderBy,
        page: 1,
      };
    case actions.SET_ORDER_BY_DIRECTION:
      return {
        ...state,
        orderByDirection: payload.orderByDirection,
        page: 1,
      };
    case actions.SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.filter]: payload.value,
        },
        page: 1,
      };
    default:
      return state;
  }
}
