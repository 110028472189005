import actions from "./actions";

const initState = {
  isLoadingUser: false,
  error: null,
  user: {},
  nextLessons: [],
  homeworks: [],
  streamUsers: [],
  streamUsersAllDataVault: [],
	ownTeacherLessons: [],
	ownTeacherStreams: [],
	individualLessons: [],
  courses: [],
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_API:
      return {
        ...state,
        isLoadingUser: true,
        error: false,
      };
    case actions.LOAD_FROM_API_SUCCESS:
      return {
        ...state,
        isLoadingUser: false,
        user: payload.user,
        error: false,
      };
    case actions.LOAD_FROM_API_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case actions.LOAD_USER_FROM_API:
      return {
        ...state,
        isLoadingUser: true,
        error: false,
      };
    case actions.LOAD_USER_FROM_API_SUCCESS:
      return {
        ...state,
        isLoadingUser: false,
        user: payload.user,
        error: false,
      };
    case actions.LOAD_USER_FROM_API_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case actions.LOAD_COURSES_FROM_API:
      return {
        ...state,
        error: false,
      };
    case actions.LOAD_COURSES_FROM_API_SUCCESS:
      return {
        ...state,
        courses: payload.courses,
        error: false,
      };
    case actions.LOAD_COURSES_FROM_API_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case actions.SAVE_TO_API:
      return {
        ...state,
        error: false,
      };
    case actions.SAVE_TO_API_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.data,
        },
        error: false,
      };
    case actions.SAVE_TO_API_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case actions.LOAD_STREAMS_FROM_API:
      return {
        ...state,
        error: false,
      };
    case actions.LOAD_STREAMS_FROM_API_SUCCESS:
      return {
        ...state,
        streamUsers: payload.streamUsers,
        streamTotalItems: payload.streamTotalItems,
        error: false,
      };
    case actions.LOAD_STREAM_ALL_DATA_FROM_API:
      return {
        ...state,
        error: false,
      };
    case actions.LOAD_STREAM_ALL_DATA_FROM_API_SUCCESS:
      return {
        ...state,
        streamUsersAllDataVault: {
          ...state.streamUsersAllDataVault,
          [payload.streamUser.id]: payload.streamUser,
        },
        error: false,
      };
    case actions.LOAD_STREAMS_FROM_API_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case actions.LOAD_NEXT_LESSONS_FROM_API:
      return {
        ...state,
        error: false,
      };
    case actions.LOAD_NEXT_LESSONS_FROM_API_SUCCESS:
      return {
        ...state,
        nextLessons: payload.nextLessons,
        error: false,
      };
    case actions.LOAD_HOMEWORKS_FROM_API:
      return {
        ...state,
        error: false,
      };
    case actions.LOAD_HOMEWORKS_FROM_API_SUCCESS:
      return {
        ...state,
        homeworks: payload.nextHomeworks,
        homeworksTotalItems: payload.totalItems,
        error: false,
      };
    case actions.TOGGLE_LESSON_HIDDEN:
      return {
        ...state,
        error: false,
      };
    case actions.TOGGLE_INDIVIDUAL_LESSON_HIDDEN:
      return {
        ...state,
        error: false,
      };
    case actions.TOGGLE_LESSON_HIDDEN_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case actions.DELETE_STREAM_USER:
      return {
        ...state,
        error: false,
      };
		case actions.LOAD_OWN_TEACHER_LESSONS:
			return {
				...state,
				error: false
			};
		case actions.LOAD_OWN_TEACHER_LESSONS_SUCCESS:
			return {
				...state,
				error: false,
				ownTeacherLessons: payload.lessons
			};
		case actions.LOAD_OWN_TEACHER_LESSONS_ERROR:
			return {
				...state,
				error: payload.error
			};
		case actions.LOAD_OWN_TEACHER_STREAMS:
			return {
				...state,
				error: false
			};
		case actions.LOAD_OWN_TEACHER_STREAMS_SUCCESS:
			return {
				...state,
				error: false,
				ownTeacherStreams: payload.streams
			};
		case actions.LOAD_OWN_TEACHER_STREAMS_ERROR:
			return {
				...state,
				error: payload.error
			};
		case actions.LOAD_INDIVIDUAL_LESSON_FROM_API:
			return {
				...state,
				error: false,
			};
		case actions.LOAD_INDIVIDUAL_LESSON_FROM_API_SUCCESS:
			return {
				...state,
				individualLessons: payload.individualLessons,
				error: false,
			};
		case actions.DELETE_TEACHER_FROM_COURSE:
			return {
				...state,
				error: false
			};
		case actions.DELETE_TEACHER_FROM_COURSE_SUCCESS:
			return state;
		case actions.DELETE_TEACHER_FROM_COURSE_ERROR:
			return {
				...state,
				error: payload.error
			};
    default:
      return state;
  }
}
