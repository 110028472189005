export const lessonInclude = [
  {
    model: 'exercise',
    as: 'exercise',
    attributes: [
      'id',
      'name',
      'description',
      'content',
      'hasTest',
      'hasHomework',
      'homework',
			'courseId'
    ],
    include: [
      {
        model: 'course',
        as: 'course',
        attributes: ['id', 'name'],
      }
    ]
  },
  {
    model: 'stream',
    as: 'stream',
    attributes: [
      'id',
      'num',
    ]
  }
]

export const homeworkInclude = [
  {
    model: 'student_homework_media',
    where: {
      deleted: false
    },
    as: 'medias',
    include: [
      {
        model: 'media',
        as: 'media'
      }
    ]
  }
]

export const homeworksInclude = () => {
  return [
    {
      model: "user",
      as: "student",
      attributes: ["firstname", "lastname"],
    },
		{
			model: "lesson_individual",
			as: "individualLesson",
			attributes: ["id", "startAt", "isIndividual"],
			include: [
				{
          model: "exercise",
          as: "exercise",
          required: false,
          where: {
            deleted: false,
          },
          attributes: ["name", "duration"],
        },
			]
		},
    {
      model: "lesson",
      as: "lesson",
      attributes: ["id", "startAt"],
      include: [
        {
          model: "exercise",
          as: "exercise",
          required: true,
          where: {
            deleted: false,
          },
          attributes: ["name", "duration"],
        },
        {
          model: "stream",
          as: "stream",
          required: true,
          where: {
            deleted: false,
          },
          attributes: ["num", "name"],
          include: [
            {
              model: "course",
              as: "course",
              required: true,
              where: {
                deleted: false,
              },
              attributes: ["name"],
              include: [
                {
                  model: "subject",
                  as: "subject",
                  required: true,
                  where: {
                    deleted: false,
                  },
                  attributes: ["name"],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
};


export const streamLessonsInclude = [
  {
    model: 'exercise',
    as: 'exercise',
    required: true,
    where: {
      deleted: false
    },
    attributes: ['id', 'name'],
    include: [
      {
        model: 'course',
        as: 'course',
        attributes: ['id', 'name'],
        where: {
          deleted: false
        },
      }
    ]
  },
]

export const mediaInclude = [
	{
		model: 'media',
		as: 'media'
	}
]