import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import api from 'api';
import actions from './actions';
import studentActions from 'redux/studentProfile/actions'

import moment from 'moment';
import _ from 'lodash'

const COLLECTION_NAME = 'operation'; // change your collection

const getUser = (state) => state.studentProfile.user
const getPage = (state) => state.operation.page;
const getItemsPerPage = (state) => state.operation.itemsPerPage;
const getFilters = (state) => state.operation.filters;

function* loadFromApi() {
  try {
    const user = yield select(getUser);
    const page = yield select(getPage);
    const itemsPerPage = yield select(getItemsPerPage);
    const filters = yield select(getFilters);

    const params = {
      query: {
        userId: user.id,
        $select: ['id'],
        $limit: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $sort: {
          time: -1
        },
        include: [
          {
            model: 'operation_type',
            as: 'type',
            required: true,
            where: {}
          }
        ]
      },
    };

    const {
      search,
      after,
      before,
      hasComment,
      adminNames,
      types
    } = filters

    if (search) {
      if (!!(+search)) params.query.transactionNum = +search
      else {
        params.query.include[0].where.name = { iLike: `%${search}%` }
        params.query.include[0].required = true
      }
    }
    if (after) params.query.time = {
      ...params.query.time,
      $gte: after
    }
    if (before) params.query.time = {
      ...params.query.time,
      $lte: moment(before).add(1, 'day')
    }
    if (hasComment) params.query.comment = {
      $ne: null
    }
    if (!_.isEmpty(types)) params.query.typeId = {
      $in: _.map(types, type => type.value)
    }
    if (!_.isEmpty(adminNames)) params.query.adminName = {
      $in: _.map(adminNames, name => name.value)
    }
    
    const data = yield call(() => api.service(COLLECTION_NAME).find(params));

    yield put(actions.loadFromApiSuccess(data.data, data.total));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadOperationTypesFromApi() {
  try {
    const user = yield select(getUser);

    const operationTypesParams = {
      query: {
        $select: ['id', 'name'],
        $sort: {
          createdAt: 1
        }
      }
    }

    const operationTypes = yield call(() => api.service('operation-type').find(operationTypesParams))

    const adminsParams = {
      query: {
        userId: user.id,
        typeId: {
          $in: ['IN_ADMIN', 'OUT_ADMIN']
        },
        adminName: {
          $ne: null
        },
        $select: ['adminName'],
        $limit: 100
      }
    }

    const adminsOperations = yield call(() => api.service('operation').find(adminsParams))
    const admins = _.uniq(
      _.map(adminsOperations.data, operation => operation.adminName)
    )

    yield put(actions.loadOperationTypesFromApiSuccess(operationTypes.data, admins));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* removeFromApi({ payload }) {
  try {
    const data = yield call(() =>
      api.service(COLLECTION_NAME).remove(payload.id)
    );

    yield put(actions.removeFromApiSuccess(data));
  } catch (error) {
    console.log('REMOVE CALL ERROR');

    console.log(error);
    yield put(actions.removeFromApiError(error));
  }
}

function* saveToApi({ payload }) {
  const { item } = payload;

  try {
    const params = {
      name: item.name,
      description: item.description,
      imageId: item.imageId,
    };

    let itemResponse = {};

    if (item.id) {
      itemResponse = yield call(() =>
        api.service(COLLECTION_NAME).patch(item.id, params)
      );
    } else {
      itemResponse = yield call(() =>
        api.service(COLLECTION_NAME).create(params)
      );
    }

    yield put(actions.saveToApiSuccess(itemResponse));
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

function* createAdminOperation({ payload }) {
  try {
    const {
      data
    } = payload

    yield call(() => api.service(COLLECTION_NAME).create(data));

    yield put(actions.setPage(1));
    yield put(actions.loadOperationTypesFromApi())
    yield put(studentActions.loadUserFromApi(data.userId))
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),
    takeEvery(actions.LOAD_OPERATION_TYPES_FROM_API, loadOperationTypesFromApi),
    takeEvery(actions.SET_PAGE, loadFromApi),
    takeEvery(actions.SET_ITEMS_PER_PAGE, loadFromApi),
    takeEvery(actions.SET_FILTER, loadFromApi),
    takeEvery(actions.SAVE_TO_API, saveToApi),
    takeEvery(actions.CREATE_ADMIN_OPERATION, createAdminOperation),
    takeEvery(actions.REMOVE_FROM_API, removeFromApi),
  ]);
}
