import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import api from 'api';
import actions from './actions';
import _ from 'lodash';
import moment from 'moment';

import { lessonInclude, homeworkInclude, streamLessonsInclude, mediaInclude } from './includes';

const getUser = (state) => state.authUser.user;
const getLessonId = (state) => state.lessonTeacherPage.lesson.id;
const getTestTab = (state) => state.lessonTeacherPage.testTab;
const getHomeworkTab = (state) => state.lessonTeacherPage.homeworkTab;

function* loadFromApi({ payload }) {
  try {
    const { lessonId } = payload;

    const user = yield select(getUser);

    const lesson = yield call(() =>
      api.service('lesson').findOne({
        query: {
          id: lessonId,
          include: lessonInclude,
					$getIndividual: true
        },
      })
    );

		if (!lesson.isIndividual && lesson.exercise) {
			lesson.exercise.exerciseMedias = (yield call(() => api.service("exercise-media").find({
				query: {
					exerciseId: lesson.exerciseId,
					include: mediaInclude
				}
			}))).data;	
		}

    const usersIds = !lesson.isIndividual ? (yield call(() =>
      api
        .service('stream-user')
        .find({
          query: {
            streamId: lesson.streamId,
            $limit: 50,
          },
        })
        .then((res) => _.map(res.data, (value) => value.userId))
    )) : [lesson.userId];

    const students = yield call(() =>
      api.service('user').find({
        query: {
          id: {
            $in: usersIds,
          },
          role: 'student',
          $limit: usersIds.length,
          $select: ['id', 'firstname', 'lastname', 'role', 'avatarId', 'points'],
        },
      })
    );

    const streamLessons = !lesson.isIndividual ? (yield call(() => api.service('lesson').find({
      query: {
        streamId: lesson.streamId,
        $select: ['id', 'startAt'],
        $sort: {
          startAt: 1
        },
        include: streamLessonsInclude
      }
    }).then(res => res.data))) : [];

    const hasAccessToLesson = _.includes(usersIds, user.id) || lesson.teacherId === user.id;

    yield put(
      actions.loadFromApiSuccess(lesson, streamLessons, students.data, hasAccessToLesson)
    );
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadTestsFromApi() {
  try {
    const lessonId = yield select(getLessonId);

    const { page, itemsPerPage } = yield select(getTestTab);

    const response = yield call(() =>
      api.service('student-test').find({
        query: {
          $or: {
						lessonId,
						lessonIndividualId: lessonId
					},
          $limit: 50,
          // $skip: (page - 1) * itemsPerPage,
          $sort: {
            submitTime: 1,
          },
        },
      })
    );

    const tests = response.data
    
    _.forEach(tests, (test, index) => {
      const result = JSON.parse(test.result)
      tests[index].result = result.quizReport
    })

    yield put(actions.loadTestsFromApiSuccess(tests, tests.total));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadHomeworksFromApi() {
  try {
    const lessonId = yield select(getLessonId);

    const { page, itemsPerPage } = yield select(getHomeworkTab);

    const homeworks = yield call(() =>
      api.service('student-homework').find({
        query: {
          status: 'submitted',
          $or: {
						lessonId,
						lessonIndividualId: lessonId
					},
          $limit: itemsPerPage,
          $skip: (page - 1) * itemsPerPage,
          $sort: {
            submitTime: 1,
          },
          include: homeworkInclude
        },
      })
    );

    yield put(
      actions.loadHomeworksFromApiSuccess(homeworks.data, homeworks.total)
    );
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* saveToApi({ payload }) {
  try {
    const lessonId = yield select(getLessonId);

    const lesson = yield call(() =>
      api.service('lesson').patch(lessonId, { ...payload.data, $editIndividual: true })
    );

    yield put(actions.saveToApiSuccess(lesson));
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

function* saveHomeworkToApi({ payload }) {
  try {
    const { homeworkId, data } = payload;

    const homework = yield call(() =>
      api.service('student-homework').patch(homeworkId, data)
    );

    yield put(actions.loadHomeworksFromApi());
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApi(error));
  }
}

function* saveTestToApi({ payload }) {
  try {
    const { testId, data } = payload;

    const test = yield call(() =>
      api.service('student-test').patch(testId, data)
    );

    const result = JSON.parse(test.result);
    test.result = result.quizReport;

    yield put(actions.saveTestToApiSuccess(test));
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApi(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),
    takeEvery(actions.LOAD_TESTS_FROM_API, loadTestsFromApi),
    takeEvery(actions.LOAD_HOMEWORKS_FROM_API, loadHomeworksFromApi),
    takeEvery(actions.SAVE_TO_API, saveToApi),
    takeEvery(actions.SET_TESTS_PAGE, loadTestsFromApi),
    takeEvery(actions.SET_HOMEWORKS_PAGE, loadHomeworksFromApi),
    takeEvery(actions.SAVE_HOMEWORK_TO_API, saveHomeworkToApi),
    takeEvery(actions.SAVE_TEST_TO_API, saveTestToApi),
  ]);
}
