import { all, takeEvery, put, call } from "redux-saga/effects";
import { NotificationManager } from "components/common/react-notifications";
import api from "./realtime";
import actions from "./actions";

import { streamLessonInclude, studentsStreamsInclude } from "./includes";

const COLLECTION_NAME = "stream"; // change your collection

function* getItem({ payload }) {
  try {
    const data = yield call(() => api.service(COLLECTION_NAME).get(payload.id));

    const streamTotalItems = yield call(() =>
      api
        .service("stream-user")
        .find({
          query: {
            streamId: data.id,
          },
        })
        .then((res) => res.total)
    );

    const streamTotalLessonItems = yield call(() =>
      api
        .service("lesson")
        .find({
          query: {
            streamId: data.id,
          },
        })
        .then((res) => res.total)
    );

    const lessons = yield call(() =>
      api
        .service("lesson")
        .find({
          query: {
            streamId: data.id,
            $select: [
              "id",
              "teacherId",
              "streamId",
              "exerciseId",
              "isActive",
              "startAt",
              "isRepeat",
              "isHidden",
              "isSoonMessageSent",
              "repeatAmount",
              "repeatInterval",
            ],
            $limit: streamTotalLessonItems,
            include: streamLessonInclude(data.id),
          },
        })
        .then((res) => res.data)
    );

    let streamUsers = yield call(() =>
        api
          .service("stream-user")
          .find({
            query: {
              streamId: data.id,
              $select: ["id", "streamId", "userId"],
              $limit: streamTotalItems,
              include: studentsStreamsInclude(),
            },
          })
          .then((res) => res.data)
      );

    if (!streamUsers.length) {
      streamUsers = yield call(() =>
        api
          .service("stream-booking")
          .find({
            query: {
              streamId: data.id,
              $select: ["id", "streamId", "userId"],
              // $limit: streamTotalItems,
              include: studentsStreamsInclude(),
            },
          })
          .then((res) => {
            return res.data
          })
      );
    }

    const subscription = yield call(() =>
      api
        .service("subscription")
        .find({
          query: {
            streamId: data.id,
          },
        })
        .then((res) => res.data)
    );

    const course = yield call(() => api.service("course").get(data.courseId));

		const totalStreamBookings = data?.isPreliminary ? (
			yield call(() => api.service("stream-booking").find({
				query: {
					streamId: data.id,
					$limit: 0
				}
			}))
		).total : 0;

		data.totalStreamBookings = totalStreamBookings;
    data.streamUsers = streamUsers;
    data.lessons = lessons;
    data.subscription = subscription;
    data.course = course;

    yield put(actions.getItemSuccess(data));
  } catch (error) {
    NotificationManager.error(
      error.message,
      error.name,
      10000,
      null,
      null,
      "filled"
    );

    yield put(actions.getItemError());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ITEM, getItem)]);
}
