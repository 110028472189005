import { all, takeEvery, put, call, select } from "redux-saga/effects";
import api from "api";
import actions from "./actions";

import _ from "lodash";

const COLLECTION_NAME = "user"; // change your collection

const getPage = (state) => state.teacher.page;
const getIsInfiniteScroll = (state) => state.teacher.isInfiniteScroll;
const getItems = (state) => state.teacher.items;
const getItemsPerPage = (state) => state.teacher.itemsPerPage;
const getOrderBy = (state) => state.teacher.orderBy;
const getOrderByDirection = (state) => state.teacher.orderByDirection;
const getFilters = (state) => state.teacher.filters;

function* loadFromApi() {
  try {
    const page = yield select(getPage);
    const isInfiniteScroll = yield select(getIsInfiniteScroll);
    const items = yield select(getItems);
    const itemsPerPage = yield select(getItemsPerPage);
    const orderBy = yield select(getOrderBy);
    const orderByDirection = yield select(getOrderByDirection);
    const filters = yield select(getFilters);

    const params = {
      $select: ["id"],
      role: "teacher",
      $limit: itemsPerPage,
      $skip: (page - 1) * itemsPerPage,
      $sort: {
        [orderBy]: orderByDirection,
      },
      // filter: {},
    };

    if (filters.search) {
      params["$or"] = [
        {
          firstname: {
            $iLike: `%${filters.search}%`,
          },
        },
        {
          lastname: {
            $iLike: `%${filters.search}%`,
          },
        },
        {
          email: {
            $iLike: `%${filters.search}%`,
          },
        },
      ];
    }

    if (filters.nameSearch) {
      const names = _.map(_.compact(_.split(filters.nameSearch, ",")), (name) =>
        _.trim(name)
      );

      const caseInsensitiveNames = _.flatten(
        _.map(names, (name) => {
          const uppercaseName = name[0].toUpperCase() + name.slice(1);
          const lowercaseName = name[0].toLowerCase() + name.slice(1);
          return [uppercaseName, lowercaseName];
        })
      );

      params["$or"] = [
        {
          firstname: {
            $in: caseInsensitiveNames,
          },
        },
        {
          lastname: {
            $in: caseInsensitiveNames,
          },
        },
      ];
    }

    if (filters.idSearch) {
      params.key = {
        $iLike: `${filters.idSearch}%`,
      };
    }

    if (filters.minCourseCount) {
      params.filter.minCourseCount = +filters.minCourseCount;
    }

    if (filters.maxCourseCount) {
      params.filter.maxCourseCount = +filters.maxCourseCount;
    }

    if (filters.minStreamCount) {
      params.filter.minStreamCount = +filters.minStreamCount;
    }

    if (filters.maxStreamCount) {
      params.filter.maxStreamCount = +filters.maxStreamCount;
    }

    const data = yield call(() =>
      api.service(COLLECTION_NAME).find({ query: params })
    );

    if (isInfiniteScroll && page > 1) {
      yield put(
        actions.loadFromApiSuccess([...items, ...data.data], data.total)
      );
    } else {
      yield put(actions.loadFromApiSuccess(data.data, data.total));
    }
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* removeFromApi({ payload }) {
  try {
    const data = yield call(() =>
      api.service(COLLECTION_NAME).remove(payload.id)
    );

    yield put(actions.removeFromApiSuccess(data));
  } catch (error) {
    console.log("REMOVE CALL ERROR");

    console.log(error);
    yield put(actions.removeFromApiError(error));
  }
}

function* saveToApi({ payload }) {
  const { item } = payload;

  try {
    const params = {
      name: item.name,
      description: item.description,
      imageId: item.imageId,
    };

    let itemResponse = {};

    if (item.id) {
      itemResponse = yield call(() =>
        api.service(COLLECTION_NAME).patch(item.id, params)
      );
    } else {
      itemResponse = yield call(() =>
        api.service(COLLECTION_NAME).create(params)
      );
    }

    yield put(actions.saveToApiSuccess(itemResponse));
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),
    takeEvery(actions.SET_PAGE, loadFromApi),
    takeEvery(actions.SET_INFINITE_SCROLL, loadFromApi),
    takeEvery(actions.SET_ITEMS_PER_PAGE, loadFromApi),
    takeEvery(actions.SET_ORDER_BY, loadFromApi),
    takeEvery(actions.SET_ORDER_BY_DIRECTION, loadFromApi),
    takeEvery(actions.SET_FILTER, loadFromApi),
    takeEvery(actions.SET_MULTIPLE_FILTER, loadFromApi),
    takeEvery(actions.SAVE_TO_API, saveToApi),
    takeEvery(actions.REMOVE_FROM_API, removeFromApi),
  ]);
}
