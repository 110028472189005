const DOCUMENT = 'LESSON_CHAT_';

const actions = {
  LOAD_FROM_API: `${DOCUMENT}LOAD_FROM_API`,
  LOAD_FROM_API_SUCCESS: `${DOCUMENT}LOAD_FROM_API_SUCCESS`,
  LOAD_FROM_API_ERROR: `${DOCUMENT}LOAD_FROM_API_ERROR`,

  LOAD_EARLY_MESSAGES_FROM_API: `${DOCUMENT}LOAD_EARLY_MESSAGES_FROM_API`,
  LOAD_EARLY_MESSAGES_FROM_API_SUCCESS: `${DOCUMENT}LOAD_EARLY_MESSAGES_FROM_API_SUCCESS`,

  MESSAGES_MARK_AS_READ: `${DOCUMENT}MESSAGES_MARK_AS_READ`,

  SAVE_TO_API: `${DOCUMENT}SAVE_TO_API`,
  SAVE_TO_API_SUCCESS: `${DOCUMENT}SAVE_TO_API_SUCCESS`,
  SAVE_TO_API_ERROR: `${DOCUMENT}SAVE_TO_API_ERROR`,

  loadFromApi: (lessonId) => {
    return {
      type: actions.LOAD_FROM_API,
      payload: {
        lessonId
      }
    };
  },

  loadFromApiSuccess: (conversations, users, currentUserId, lessonId) => ({
    type: actions.LOAD_FROM_API_SUCCESS,
    payload: {
      conversations,
      users,
      currentUserId,
      lessonId
    },
  }),

  loadEarlyMessagesFromApi: (data) => {
    return {
      type: actions.LOAD_EARLY_MESSAGES_FROM_API,
      payload: {
        ...data
      }
    };
  },

  loadEarlyMessagesFromApiSuccess: (messages, total, converastionId) => ({
    type: actions.LOAD_EARLY_MESSAGES_FROM_API_SUCCESS,
    payload: {
      messages,
      total,
      converastionId
    },
  }),

  loadFromApiError: (error) => ({
    type: actions.LOAD_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  messagesMarkAsRead: (conversationId) => ({
    type: actions.MESSAGES_MARK_AS_READ,
    payload: {
      conversationId
    }
  }),

  saveToApi: (messageData) => {
    return {
      type: actions.SAVE_TO_API,
      payload: {
        messageData
      }
    };
  },

  saveToApiSuccess: (message) => ({
    type: actions.SAVE_TO_API_SUCCESS,
    payload: {
      message,
    },
  }),

  saveToApiError: (error) => ({
    type: actions.SAVE_TO_API_ERROR,
    payload: {
      error,
    },
  }),
};

export default actions;
