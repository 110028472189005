import actions from './actions';

const initState = {
  isLoading: false,
  error: null,
  operations: [],
  operationTypes: [],
  admins: [],
  filters: {
    search: null,
    types: [],
    after: null,
    before: null,
    hasComment: false,
    adminNames: []
  },
  page: 1,
  totalItems: 0,
  itemsPerPage: 12,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_API:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case actions.LOAD_FROM_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operations: payload.data,
        totalItems: payload.totalItems,
        error: false,
      };
    case actions.LOAD_OPERATION_TYPES_FROM_API:
      return {
        ...state,
        error: false,
      };
    case actions.LOAD_OPERATION_TYPES_FROM_API_SUCCESS:
      return {
        ...state,
        operationTypes: payload.operationTypes,
        admins: payload.admins,
        error: false,
      };
    case actions.LOAD_FROM_API_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case actions.REMOVE_FROM_API:
      return {
        ...state,
        error: false,
      };
    case actions.REMOVE_FROM_API_SUCCESS:
      return {
        ...state,
        error: false,
      };
    case actions.REMOVE_FROM_API_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case actions.SAVE_TO_API:
      return {
        ...state,
        error: false,
      };
    case actions.SAVE_TO_API_SUCCESS:
      return {
        ...state,
        error: false,
      };
    case actions.SAVE_TO_API_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case actions.SET_PAGE:
      return {
        ...state,
        page: payload.page,
      };
    case actions.SET_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: payload.itemsPerPage,
        page: 1,
      };
    case actions.SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload.data,
        },
        page: 1,
      };
    case actions.RESET_FILTER:
      return {
        ...state,
        filters: {
          ...initState.filters
        },
        page: 1,
      };
    default:
      return state;
  }
}
