import actions from './actions';
import _ from 'lodash';
import { homeworksInclude } from './includes';

const initState = {
  isLoading: false,
  error: null,
  lesson: {},
  streamLessons: [],
  hasAccessToLesson: true,
  students: [],
  homeworkTab: {
    isLoadingHomeworks: false,
    itemsPerPage: 10,
    page: 1,
    homeworks: [],
    totalItems: 0,
  },
  testTab: {
    isLoadingTests: false,
    itemsPerPage: 10,
    page: 1,
    tests: [],
    totalItems: 0,
  },
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_API:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case actions.LOAD_FROM_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lesson: payload.lesson,
        streamLessons: payload.streamLessons,
        students: payload.students,
        hasAccessToLesson: payload.hasAccessToLesson,
        error: false,
      };
    case actions.LOAD_FROM_API_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case actions.LOAD_TESTS_FROM_API:
      return {
        ...state,
        testTab: {
          ...state.testTab,
          isLoadingTests: true,
        },
        error: false,
      };
    case actions.LOAD_TESTS_FROM_API_SUCCESS:
      return {
        ...state,
        testTab: {
          ...state.testTab,
          isLoadingTests: false,
          tests: payload.tests,
          totalItems: payload.totalItems,
        },
        error: false,
      };
    case actions.LOAD_HOMEWORKS_FROM_API:
      return {
        ...state,
        homeworkTab: {
          ...state.homeworkTab,
          isLoadingHomeworks: true,
        },
        error: false,
      };
    case actions.LOAD_HOMEWORKS_FROM_API_SUCCESS:
      return {
        ...state,
        homeworkTab: {
          ...state.homeworkTab,
          isLoadingHomeworks: false,
          homeworks: payload.homeworks,
          totalItems: payload.totalItems,
        },
        error: false,
      };
    case actions.SAVE_TO_API:
      return {
        ...state,
        error: false,
      };
    case actions.SAVE_TO_API_SUCCESS:
      return {
        ...state,
        error: false,
        lesson: {
          ...state.lesson,
          ...payload.lesson
        },
      };
    case actions.SAVE_TO_API_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case actions.SET_TESTS_PAGE:
      return {
        ...state,
        testTab: {
          ...state.testTab,
          page: payload.page,
        },
      };
    case actions.SET_HOMEWORKS_PAGE:
      return {
        ...state,
        homeworkTab: {
          ...state.homeworkTab,
          page: payload.page,
        },
      };
    case actions.SAVE_HOMEWORK_TO_API:
      return {
        ...state,
        error: false,
      };
    case actions.SAVE_HOMEWORK_TO_API_SUCCESS:
      const homeworks = state.homeworkTab.homeworks;
      const homeworkIndex = _.findIndex(
        homeworks,
        (homework) => homework.id === payload.homework.id
      );
      homeworks[homeworkIndex] = payload.homework;

      return {
        ...state,
        error: false,
        homeworkTab: {
          ...state.homeworkTab,
          homeworks,
        },
      };
    case actions.SAVE_TEST_TO_API:
      return {
        ...state,
        error: false,
      };
    case actions.SAVE_TEST_TO_API_SUCCESS:
      const tests = state.testTab.tests;
      const testIndex = _.findIndex(
        tests,
        (test) => test.id === payload.test.id
      );
      tests[testIndex] = payload.test;

      return {
        ...state,
        error: false,
        testTab: {
          ...state.testTab,
          tests,
        },
      };
    default:
      return state;
  }
}
