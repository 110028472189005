import { all, takeEvery, put, call } from 'redux-saga/effects';
import { NotificationManager } from 'components/common/react-notifications';
import api from './realtime';
import actions from './actions';

const COLLECTION_NAME = 'media'; // change your collection

function* getItem({ payload }) {
  try {
    const data = yield call(() => api.service(COLLECTION_NAME).get(payload.id));

    yield put(actions.getItemSuccess(data));
  } catch (error) {
    NotificationManager.error(
      error.message,
      error.name,
      10000,
      null,
      null,
      'filled'
    );

    yield put(actions.getItemError());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ITEM, getItem)]);
}
