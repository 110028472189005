import { all, takeEvery, put, call, select } from "redux-saga/effects";
import api from "api";
import actions from "./actions";

const COLLECTION_NAME = "exercise"; // change your collection
const COLLECTION_COURSE_NAME = "course"; // change your collection

const getPage = (state) => state.exercise.page;
const getItemsPerPage = (state) => state.exercise.itemsPerPage;
const getOrderBy = (state) => state.exercise.orderBy;
const getOrderByDirection = (state) => state.exercise.orderByDirection;
const getFilters = (state) => state.exercise.filters;

function* loadFromApi({ payload }) {
  try {
    const page = yield select(getPage);
    const itemsPerPage = yield select(getItemsPerPage);
    const orderBy = yield select(getOrderBy);
    const orderByDirection = yield select(getOrderByDirection);
    const filters = yield select(getFilters);

    const params = {
      query: {
        // courseId: payload.courseId ? payload.courseId : undefined,
        $select: ["id"],
        $or: [
          {
            name: {
              $iLike: `%${filters.search}%`,
            },
          },
          {
            description: {
              $iLike: `%${filters.search}%`,
            },
          },
        ],
        $limit: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $sort: {
          [orderBy]: orderByDirection,
        },
      },
    };

    const data = yield call(() => api.service(COLLECTION_NAME).find(params));

    yield put(actions.loadFromApiSuccess(data.data, data.total));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadCoursesFromApi() {
  try {
    const page = 1;
    const itemsPerPage = 100;
    const orderBy = "name";
    const orderByDirection = 1;
    const filters = {
      search: "",
    };

    const params = {
      query: {
        $select: ["id", "name"],
        $or: [
          {
            name: {
              $iLike: `%${filters.search}%`,
            },
          },
          {
            description: {
              $iLike: `%${filters.search}%`,
            },
          },
        ],
        $limit: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $sort: {
          [orderBy]: orderByDirection,
        },
      },
    };

    const data = yield call(() =>
      api.service(COLLECTION_COURSE_NAME).find(params)
    );

    yield put(actions.loadCoursesFromApiSuccess(data.data, data.total));
  } catch (error) {
    console.log(error);
    yield put(actions.loadCoursesFromApiError(error));
  }
}

function* removeFromApi({ payload }) {
  try {
    const data = yield call(() =>
      api.service(COLLECTION_NAME).remove(payload.id)
    );

    yield put(actions.removeFromApiSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(actions.removeFromApiError(error));
  }
}

function* saveToApi({ payload }) {
  const { item } = payload;

  try {
    const params = {
      name: item.name,
      description: item.description,
      content: item.content,
      homework: item.homework,
      isInviteAllowed: item.isInviteAllowed,
      duration: item.duration,
      exerciseMedias: item.exerciseMedias,
      exerciseAds: item.exerciseAds,
      courseId: item.courseId,
    };

    let itemResponse = {};

    if (item.id) {
      itemResponse = yield call(() =>
        api.service(COLLECTION_NAME).patch(item.id, params)
      );
    } else {
      itemResponse = yield call(() =>
        api.service(COLLECTION_NAME).create(params)
      );
    }

    yield put(actions.saveToApiSuccess(itemResponse));
    yield put(actions.loadFromApi(item.courseId));
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

function* saveSortingToApi({ payload }) {
  const { items } = payload;

  try {
    yield all(
      items.map((item, index) =>
        call(() =>
          api.service(COLLECTION_NAME).patch(item.id, {
            orderBy: index,
          })
        )
      )
    );

    yield put(actions.saveSortingToApiSuccess());
  } catch (error) {
    console.log(error);
    yield put(actions.saveSortingToApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),
    takeEvery(actions.LOAD_COURSES_FROM_API, loadCoursesFromApi),
    takeEvery(actions.SET_PAGE, loadFromApi),
    takeEvery(actions.SET_ITEMS_PER_PAGE, loadFromApi),
    takeEvery(actions.SET_ORDER_BY, loadFromApi),
    takeEvery(actions.SET_ORDER_BY_DIRECTION, loadFromApi),
    takeEvery(actions.SET_FILTER, loadFromApi),
    takeEvery(actions.SAVE_TO_API, saveToApi),
    takeEvery(actions.SAVE_SORTING_TO_API, saveSortingToApi),
    takeEvery(actions.REMOVE_FROM_API, removeFromApi),
  ]);
}
