const DOCUMENT = 'NOTE_';

const actions = {
  LOAD_FROM_API: `${DOCUMENT}LOAD_FROM_API`,
  LOAD_FROM_API_SUCCESS: `${DOCUMENT}LOAD_FROM_API_SUCCESS`,
  LOAD_FROM_API_ERROR: `${DOCUMENT}LOAD_FROM_API_ERROR`,

  SAVE_TO_API: `${DOCUMENT}SAVE_TO_API`,
  SAVE_TO_API_SUCCESS: `${DOCUMENT}SAVE_TO_API_SUCCESS`,
  SAVE_TO_API_ERROR: `${DOCUMENT}SAVE_TO_API_ERROR`,

  REMOVE_FROM_API: `${DOCUMENT}REMOVE_FROM_API`,
  REMOVE_FROM_API_SUCCESS: `${DOCUMENT}REMOVE_FROM_API_SUCCESS`,
  REMOVE_FROM_API_ERROR: `${DOCUMENT}REMOVE_FROM_API_ERROR`,

  SET_PAGE: `${DOCUMENT}SET_PAGE`,
  SET_ITEMS_PER_PAGE: `${DOCUMENT}SET_ITEMS_PER_PAGE`,
  SET_ORDER_BY: `${DOCUMENT}SET_ORDER_BY`,
  SET_ORDER_BY_DIRECTION: `${DOCUMENT}SET_ORDER_BY_DIRECTION`,
  SET_FILTER: `${DOCUMENT}SET_FILTER`,

  loadFromApi: () => {
    return {
      type: actions.LOAD_FROM_API,
    };
  },

  loadFromApiSuccess: (data, totalItems) => ({
    type: actions.LOAD_FROM_API_SUCCESS,
    payload: {
      data,
      totalItems,
    },
  }),

  loadFromApiError: (error) => ({
    type: actions.LOAD_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  removeFromApi: (id) => {
    return {
      type: actions.REMOVE_FROM_API,
      payload: {
        id,
      },
    };
  },

  removeFromApiSuccess: (item) => ({
    type: actions.REMOVE_FROM_API_SUCCESS,
    payload: {
      item,
    },
  }),

  removeFromApiError: (error) => ({
    type: actions.REMOVE_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  saveToApi: (item) => ({
    type: actions.SAVE_TO_API,
    payload: {
      item,
    },
  }),

  saveToApiSuccess: (item) => ({
    type: actions.SAVE_TO_API_SUCCESS,
    payload: {
      item,
    },
  }),

  saveToApiError: (error) => ({
    type: actions.SAVE_TO_API_ERROR,
    payload: {
      error,
    },
  }),

  setPage: (page) => ({
    type: actions.SET_PAGE,
    payload: {
      page,
    },
  }),

  setItemsPerPage: (itemsPerPage) => ({
    type: actions.SET_ITEMS_PER_PAGE,
    payload: {
      itemsPerPage,
    },
  }),

  setOrderBy: (orderBy) => ({
    type: actions.SET_ORDER_BY,
    payload: {
      orderBy,
    },
  }),

  setOrderByDirection: (orderByDirection) => ({
    type: actions.SET_ORDER_BY_DIRECTION,
    payload: {
      orderByDirection,
    },
  }),

  setFilter: (filter, value) => ({
    type: actions.SET_FILTER,
    payload: {
      filter,
      value,
    },
  }),
};

export default actions;
