import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import api from 'api';
import actions from './actions';

const COLLECTION_NAME = 'message-template'; // change your collection

const getPage = (state) => state.newsletter.page;
const getItemsPerPage = (state) => state.newsletter.itemsPerPage;
const getOrderBy = (state) => state.newsletter.orderBy;
const getOrderByDirection = (state) => state.newsletter.orderByDirection;
const getFilters = (state) => state.newsletter.filters;

function* loadFromApi() {
  try {
    const page = yield select(getPage);
    const itemsPerPage = yield select(getItemsPerPage);
    const orderBy = yield select(getOrderBy);
    const orderByDirection = yield select(getOrderByDirection);
    const filters = yield select(getFilters);

    const params = {
      query: {
        $select: ['id'],
        $or: [
          {
            text: {
              $iLike: `%${filters.search}%`,
            },
          },
        ],
        $limit: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $sort: {
          [orderBy]: orderByDirection,
        },
      },
    };

    const data = yield call(() => api.service(COLLECTION_NAME).find(params));

    yield put(actions.loadFromApiSuccess(data.data, data.total));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* removeFromApi({ payload }) {
  try {
    const data = yield call(() =>
      api.service(COLLECTION_NAME).remove(payload.id)
    );

    yield put(actions.removeFromApiSuccess(data));
  } catch (error) {
    console.log('REMOVE CALL ERROR');

    console.log(error);
    yield put(actions.removeFromApiError(error));
  }
}

function* saveToApi({ payload }) {
  const { item } = payload;

  try {
    const messageTemplateParams = {
      subject: item.subject,
      text: item.text,
      sendAt: item.sendAt,
      status: item.status,
    };

    const messageDistributionParams = {
      filter: item.filter,
      type: 'email',
    };

    let messageTemplate = {};
    let messageDistribution = {};

    if (item.id) {
      messageTemplate = yield call(() =>
        api.service(COLLECTION_NAME).patch(item.id, messageTemplateParams)
      );

      messageDistribution = yield call(() =>
        api
          .service('message-distribution')
          .patch(item.distributionId, messageDistributionParams)
      );
    } else {
      messageTemplate = yield call(() =>
        api.service(COLLECTION_NAME).create(messageTemplateParams)
      );

      messageDistribution = yield call(() =>
        api.service('message-distribution').create({
          ...messageDistributionParams,
          templateId: messageTemplate.id,
        })
      );
    }

    messageTemplate.messageDistribution = messageDistribution;

    yield put(actions.saveToApiSuccess(messageTemplate));
    yield put(actions.setPage(1));
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),
    takeEvery(actions.SET_PAGE, loadFromApi),
    takeEvery(actions.SET_ITEMS_PER_PAGE, loadFromApi),
    takeEvery(actions.SET_ORDER_BY, loadFromApi),
    takeEvery(actions.SET_ORDER_BY_DIRECTION, loadFromApi),
    takeEvery(actions.SET_FILTER, loadFromApi),
    takeEvery(actions.SAVE_TO_API, saveToApi),
    takeEvery(actions.REMOVE_FROM_API, removeFromApi),
  ]);
}
