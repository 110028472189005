const DOCUMENT = 'REGISTER_OF_PAYMENTS_';

const actions = {
  LOAD_FROM_API: `${DOCUMENT}LOAD_FROM_API`,
  LOAD_FROM_API_SUCCESS: `${DOCUMENT}LOAD_FROM_API_SUCCESS`,
  LOAD_FROM_API_ERROR: `${DOCUMENT}LOAD_FROM_API_ERROR`,

  LOAD_OPERATION_TYPES_FROM_API: `${DOCUMENT}LOAD_OPERATION_TYPES_FROM_API`,
  LOAD_OPERATION_TYPES_FROM_API_SUCCESS: `${DOCUMENT}LOAD_OPERATION_TYPES_FROM_API_SUCCESS`,

  CREATE_ADMIN_OPERATION: `${DOCUMENT}CREATE_ADMIN_OPERATION`,

  SET_PAGE: `${DOCUMENT}SET_PAGE`,
  SET_ITEMS_PER_PAGE: `${DOCUMENT}SET_ITEMS_PER_PAGE`,
  SET_FILTER: `${DOCUMENT}SET_FILTER`,
  RESET_FILTER: `${DOCUMENT}RESET_FILTER`,

  loadFromApi: () => {
    return {
      type: actions.LOAD_FROM_API,
    };
  },

  loadFromApiSuccess: ({
    data,
    totalItems,
    amountPositive,
    amountNegative,
  }) => ({
    type: actions.LOAD_FROM_API_SUCCESS,
    payload: {
      data,
      totalItems,
      amountPositive,
      amountNegative,
    },
  }),

  loadFromApiError: (error) => ({
    type: actions.LOAD_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  loadOperationTypesFromApi: () => {
    return {
      type: actions.LOAD_OPERATION_TYPES_FROM_API,
    };
  },

  loadOperationTypesFromApiSuccess: (operationTypes, admins) => ({
    type: actions.LOAD_OPERATION_TYPES_FROM_API_SUCCESS,
    payload: {
      operationTypes,
      admins,
    },
  }),

  createAdminOperation: (data) => ({
    type: actions.CREATE_ADMIN_OPERATION,
    payload: {
      data,
    },
  }),

  setPage: (page) => ({
    type: actions.SET_PAGE,
    payload: {
      page,
    },
  }),

  setItemsPerPage: (itemsPerPage) => ({
    type: actions.SET_ITEMS_PER_PAGE,
    payload: {
      itemsPerPage,
    },
  }),

  setFilter: (data) => ({
    type: actions.SET_FILTER,
    payload: {
      data,
    },
  }),

  resetFilter: () => ({
    type: actions.RESET_FILTER,
  }),
};

export default actions;
