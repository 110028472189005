import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import _ from 'lodash'

import app from 'api';

import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../root-actions';

import {
  loginUser,
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

const COLLECTION_USER_NAME = 'user';
const COLLECTION_PREFERED_DAY_NAME = 'prefered-day';

const loginWithEmailPasswordAsync = async (email, password) =>
  app
    .authenticate({
      strategy: 'local',
      email,
      password,
    })
    .then((authUser) => authUser)
    .catch((error) => error);

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;

  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);

    if (!loginUser.message) {
      localStorage.setItem('userId', loginUser.admin.id);
      yield put(loginUserSuccess(loginUser.admin));
      history.push('/');
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  app
    .createUserWithEmailAndPassword(email, password)
    .then((authUser) => authUser)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password,firstName, lastName, age, city, countryPhone, phone, daySelect  } = payload.user;
  const { history } = payload;

  try {
    const dataUser = {
      email,
      password,
      firstname: firstName,
      lastname: lastName,
      age,
      phone: countryPhone + phone,
      city,
      role: 'teacher'
    };

    const createUser = yield call(() =>
      app.service(COLLECTION_USER_NAME).create(dataUser)
    );

    if (!_.isNull(createUser)) {
      yield put(
        loginUser({ email: dataUser.email, password: dataUser.password })
      );
      yield all(
        _.map(daySelect, (dayNum) =>
          call(() =>
            app
              .service(COLLECTION_PREFERED_DAY_NAME)
              .create({ userId: createUser.id, dayNum })
          )
        )
      );

      yield put(registerUserSuccess(createUser));
      yield put(history.push('/'));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const logoutAsync = async (history) => {
  await app
    .logout()
    .then((authUser) => authUser)
    .catch((error) => error);
  history.push('/');
};

function* logout({ payload }) {
  const { history } = payload;
  try {
    yield call(logoutAsync, history);
    localStorage.removeItem('userId');
    localStorage.removeItem('auth');
  } catch (error) {
    console.error(error);
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const forgotPasswordAsync = async (email) => {
  return app
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return app
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
