const DOCUMENT = "STREAM_";

const actions = {
  LOAD_FROM_API: `${DOCUMENT}LOAD_FROM_API`,
  LOAD_FROM_API_SUCCESS: `${DOCUMENT}LOAD_FROM_API_SUCCESS`,
  LOAD_FROM_API_ERROR: `${DOCUMENT}LOAD_FROM_API_ERROR`,

  LOAD_COURSES_FROM_API: `${DOCUMENT}LOAD_COURSES_FROM_API`,
  LOAD_COURSES_FROM_API_SUCCESS: `${DOCUMENT}LOAD_COURSES_FROM_API_SUCCESS`,
  LOAD_COURSES_FROM_API_ERROR: `${DOCUMENT}LOAD_COURSES_FROM_API_ERROR`,

  LOAD_EXERCISES_FROM_API: `${DOCUMENT}LOAD_EXERCISES_FROM_API`,
  LOAD_EXERCISES_FROM_API_SUCCESS: `${DOCUMENT}LOAD_EXERCISES_FROM_API_SUCCESS`,
  LOAD_EXERCISES_FROM_API_ERROR: `${DOCUMENT}LOAD_EXERCISES_FROM_API_ERROR`,

  LOAD_USERS_FROM_API: `${DOCUMENT}LOAD_USERS_FROM_API`,
  LOAD_USERS_FROM_API_SUCCESS: `${DOCUMENT}LOAD_USERS_FROM_API_SUCCESS`,
  LOAD_USERS_FROM_API_ERROR: `${DOCUMENT}LOAD_USERS_FROM_API_ERROR`,

  SAVE_TO_API: `${DOCUMENT}SAVE_TO_API`,
  SAVE_TO_API_SUCCESS: `${DOCUMENT}SAVE_TO_API_SUCCESS`,
  SAVE_TO_API_ERROR: `${DOCUMENT}SAVE_TO_API_ERROR`,

  SAVE_LESSON_TO_API: `${DOCUMENT}SAVE_LESSON_TO_API`,
  SAVE_LESSON_TO_API_SUCCESS: `${DOCUMENT}SAVE_LESSON_TO_API_SUCCESS`,
  SAVE_LESSON_TO_API_ERROR: `${DOCUMENT}SAVE_LESSON_TO_API_ERROR`,

  REMOVE_LESSON_FROM_API: `${DOCUMENT}REMOVE_LESSON_FROM_API`,
  REMOVE_LESSON_FROM_API_SUCCESS: `${DOCUMENT}REMOVE_LESSON_FROM_API_SUCCESS`,
  REMOVE_LESSON_FROM_API_ERROR: `${DOCUMENT}REMOVE_LESSON_FROM_API_ERROR`,

  SAVE_MANY_LESSONS_TO_API: `${DOCUMENT}SAVE_MANY_LESSONS_TO_API`,
  SAVE_MANY_LESSONS_TO_API_SUCCESS: `${DOCUMENT}SAVE_MANY_LESSONS_TO_API_SUCCESS`,
  SAVE_MANY_LESSONS_TO_API_ERROR: `${DOCUMENT}SAVE_MANY_LESSONS_TO_API_ERROR`,

  SAVE_SUBSCRIPTION_TO_API: `${DOCUMENT}SAVE_SUBSCRIPTION_TO_API`,
  SAVE_SUBSCRIPTION_TO_API_SUCCESS: `${DOCUMENT}SAVE_SUBSCRIPTION_TO_API_SUCCESS`,
  SAVE_SUBSCRIPTION_TO_API_ERROR: `${DOCUMENT}SAVE_SUBSCRIPTION_TO_API_ERROR`,

  REMOVE_FROM_API: `${DOCUMENT}REMOVE_FROM_API`,
  REMOVE_FROM_API_SUCCESS: `${DOCUMENT}REMOVE_FROM_API_SUCCESS`,
  REMOVE_FROM_API_ERROR: `${DOCUMENT}REMOVE_FROM_API_ERROR`,
  
  CANCEL_FROM_API: `${DOCUMENT}CANCEL_FROM_API`,
  CANCEL_FROM_API_SUCCESS: `${DOCUMENT}CANCEL_FROM_API_SUCCESS`,
  CANCEL_FROM_API_ERROR: `${DOCUMENT}CANCEL_FROM_API_ERROR`,

  SET_PAGE: `${DOCUMENT}SET_PAGE`,
  SET_ITEMS_PER_PAGE: `${DOCUMENT}SET_ITEMS_PER_PAGE`,
  SET_ORDER_BY: `${DOCUMENT}SET_ORDER_BY`,
  SET_ORDER_BY_DIRECTION: `${DOCUMENT}SET_ORDER_BY_DIRECTION`,
  SET_FILTER: `${DOCUMENT}SET_FILTER`,

  loadFromApi: (courseId = null) => {
    return {
      type: actions.LOAD_FROM_API,
      payload: {
        courseId,
      },
    };
  },

  loadFromApiSuccess: (data, totalItems) => ({
    type: actions.LOAD_FROM_API_SUCCESS,
    payload: {
      data,
      totalItems,
    },
  }),

  loadFromApiError: (error) => ({
    type: actions.LOAD_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  loadCoursesFromApi: () => {
    return {
      type: actions.LOAD_COURSES_FROM_API,
    };
  },

  loadCoursesFromApiSuccess: (data, totalItems) => ({
    type: actions.LOAD_COURSES_FROM_API_SUCCESS,
    payload: {
      data,
      totalItems,
    },
  }),

  loadCoursesFromApiError: (error) => ({
    type: actions.LOAD_COURSES_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  loadUsersFromApi: () => {
    return {
      type: actions.LOAD_USERS_FROM_API,
    };
  },

  loadUsersFromApiSuccess: (data, totalItems) => ({
    type: actions.LOAD_USERS_FROM_API_SUCCESS,
    payload: {
      data,
      totalItems,
    },
  }),

  loadUsersFromApiError: (error) => ({
    type: actions.LOAD_USERS_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  loadExercisesFromApi: (id) => {
    return {
      type: actions.LOAD_EXERCISES_FROM_API,
      payload: { id },
    };
  },

  loadExercisesFromApiSuccess: (data, totalItems) => ({
    type: actions.LOAD_EXERCISES_FROM_API_SUCCESS,
    payload: {
      data,
      totalItems,
    },
  }),

  loadExercisesFromApiError: (error) => ({
    type: actions.LOAD_EXERCISES_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  removeFromApi: (id) => {
    return {
      type: actions.REMOVE_FROM_API,
      payload: {
        id,
      },
    };
  },

  removeFromApiSuccess: (item) => ({
    type: actions.REMOVE_FROM_API_SUCCESS,
    payload: {
      item,
    },
  }),

  removeFromApiError: (error) => ({
    type: actions.REMOVE_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  cancelFromApi: (id) => {
    return {
      type: actions.CANCEL_FROM_API,
      payload: {
        id,
      },
    };
  },

  cancelFromApiSuccess: (item) => ({
    type: actions.CANCEL_FROM_API_SUCCESS,
    payload: {
      item,
    },
  }),

  cancelFromApiError: (error) => ({
    type: actions.CANCEL_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  saveToApi: (item, subscriptions) => ({
    type: actions.SAVE_TO_API,
    payload: {
      item,
      subscriptions,
    },
  }),

  saveToApiSuccess: (item, subscriptions) => ({
    type: actions.SAVE_TO_API_SUCCESS,
    payload: {
      item,
      subscriptions,
    },
  }),

  saveToApiError: (error) => ({
    type: actions.SAVE_TO_API_ERROR,
    payload: {
      error,
    },
  }),

  saveLessonToApi: (item) => ({
    type: actions.SAVE_LESSON_TO_API,
    payload: {
      item,
    },
  }),

  saveLessonToApiSuccess: (item) => ({
    type: actions.SAVE_LESSON_TO_API_SUCCESS,
    payload: {
      item,
    },
  }),

  saveLessonToApiError: (error) => ({
    type: actions.SAVE_LESSON_TO_API_ERROR,
    payload: {
      error,
    },
  }),

  removeLessonFromApi: (id) => ({
    type: actions.REMOVE_LESSON_FROM_API,
    payload: {
      id,
    },
  }),

  removeLessonFromApiSuccess: (item) => ({
    type: actions.REMOVE_LESSON_FROM_API_SUCCESS,
    payload: {
      item,
    },
  }),

  removeLessonFromApiError: (error) => ({
    type: actions.REMOVE_LESSON_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  saveManyLessonToApi: (item) => ({
    type: actions.SAVE_MANY_LESSONS_TO_API,
    payload: {
      item,
    },
  }),

  saveManyLessonToApiSuccess: (item) => ({
    type: actions.SAVE_MANY_LESSONS_TO_API_SUCCESS,
    payload: {
      item,
    },
  }),

  saveManyLessonToApiError: (error) => ({
    type: actions.SAVE_MANY_LESSONS_TO_API_ERROR,
    payload: {
      error,
    },
  }),

  saveSubscriptionToApi: (item) => ({
    type: actions.SAVE_SUBSCRIPTION_TO_API,
    payload: {
      item,
    },
  }),

  saveSubscriptionToApiSuccess: (item) => ({
    type: actions.SAVE_SUBSCRIPTION_TO_API_SUCCESS,
    payload: {
      item,
    },
  }),

  saveSubscriptionToApiError: (error) => ({
    type: actions.SAVE_SUBSCRIPTION_TO_API_ERROR,
    payload: {
      error,
    },
  }),

  setPage: (page) => ({
    type: actions.SET_PAGE,
    payload: {
      page,
    },
  }),

  setItemsPerPage: (itemsPerPage) => ({
    type: actions.SET_ITEMS_PER_PAGE,
    payload: {
      itemsPerPage,
    },
  }),

  setOrderBy: (orderBy) => ({
    type: actions.SET_ORDER_BY,
    payload: {
      orderBy,
    },
  }),

  setOrderByDirection: (orderByDirection) => ({
    type: actions.SET_ORDER_BY_DIRECTION,
    payload: {
      orderByDirection,
    },
  }),

  setFilter: (filter, value) => ({
    type: actions.SET_FILTER,
    payload: {
      filter,
      value,
    },
  }),
};

export default actions;
