import { all, takeEvery, put, call } from 'redux-saga/effects';
import { NotificationManager } from 'components/common/react-notifications';
import api from './realtime';
import actions from './actions';

import { ownTeacherStreamsInclude, teacherStreamsInclude } from './includes';

const COLLECTION_NAME = 'user'; // change your collection

function* getItem({ payload }) {
  try {
    const data = yield call(() =>
      api.service(COLLECTION_NAME).findOne({
        query: {
          id: payload.id,
          include: [
            {
              model: 'app_visit',
              as: 'appVisit',
              atrributes: ['id', 'visitDate'],
              limit: 1,
              order: [['visitDate', 'DESC']],
            },
          ],
        },
      })
    );

		const ownTeacherLessonsTotalItems = (yield call(() => api.service('lesson').find({
			query: {
				isHidden: false,
				teacherId: data?.id,
				include: ownTeacherStreamsInclude(),
				$limit: 0
			}
		}))).total;

		const ownTeacherLessons = yield call(() => api.service('lesson').find({
			query: {
				isHidden: false,
				teacherId: data?.id,
				include: ownTeacherStreamsInclude(),
				$limit: ownTeacherLessonsTotalItems
			},
		}));

		const individualLessonsTotalItems = (yield call(() =>
			api
			.service("lesson-individual")
			.find({
				query: {
					teacherId: {
						$in: [data?.id],
					},
					$limit: 0
				},
			})
    )).total;

		const individualLessons = yield call(() =>
			api
			.service("lesson-individual")
			.find({
				query: {
					$select: [
						"id",
						"userId",
						"teacherId",
						"courseId",
						"subjectId",
						"startAt",
						"finishAt",
						"isIndividual",
						"isHidden",
					],
					teacherId: {
						$in: [data?.id],
					},
					$limit: individualLessonsTotalItems
				},
			})
    );

    const streamTotalItems = yield call(() =>
      api
        .service('stream-user')
        .find({
          query: {
            userId: data.id,
          },
        })
        .then((res) => res.total)
    );

    const streamUsers = yield call(() =>
      api
        .service('stream-user')
        .find({
          query: {
            userId: data.id,
            $select: ['id', 'streamId', 'userId'],
            $limit: streamTotalItems,
            include: teacherStreamsInclude(data.id),
          },
        })
        .then((res) => res.data)
    );

    data.streamUsers = streamUsers;
		data.ownTeacherLessons = ownTeacherLessons.data;
		data.individualLessons = individualLessons.data;

    yield put(actions.getItemSuccess(data));
  } catch (error) {
    NotificationManager.error(
      error.message,
      error.name,
      10000,
      null,
      null,
      'filled'
    );

    yield put(actions.getItemError());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ITEM, getItem)]);
}
