import api from 'api';

// eslint-disable-next-line import/no-cycle
import store from 'redux/store';

import actions from './actions';

import _ from 'lodash'

api.service('chat-message').on('created', async (message) => {
  const path = _.split(window.location.pathname, '/').slice(1)
  if (
    path[0] === 'lesson' &&
    (path[1] === message.lessonId || path[1] === message.lessonIndividualId)
  ) {
    store.dispatch(actions.saveToApiSuccess(message));
  }
});

export default api;
