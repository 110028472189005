import { all, takeEvery, put, call, select } from "redux-saga/effects";
import api from "api";
import actions from "./actions";
import _ from "lodash";
import moment from "moment";

import {
  studentStreamsInclude,
} from './includes';

const COLLECTION_NAME = "user";

const getUser = (state) => state.studentProfile.user;

function* loadFromApi({ payload }) {
  try {
    const { userId, startDate } = payload;

    const firstDayOfCalendar = moment(
      moment(startDate).startOf('month')
    ).startOf('week')._d;
    const lastDayOfCalendar = moment(moment(startDate).endOf('month')).endOf(
      'week'
    )._d;

    const user = yield call(() => api.service(COLLECTION_NAME).get(userId));
    
    const userSubscription = yield call(() =>
      api
        .service('user-subscription')
        .find({
          query: {
            $startDate: firstDayOfCalendar,
            $endDate: lastDayOfCalendar,
            userId,
            include: [
              {
                model: 'subscription',
                as: 'subscription',
								include: studentStreamsInclude(userId)
              },
            ],
						$limit: 50,
            $sort: {
              createdAt: -1
            }
          },
        })
        .then((res) => res.data)
    );

		const individualLessonsCount = yield call(() => 
			api
				.service('lesson-individual')
				.find({
					query: {
						$select: ['id'],
						$limit: 0,
						userId: {
              $in: [userId],
            },
					}
				})
			.then((result) => result.total));

    const individualLesson = yield call(() =>
      api
        .service("lesson-individual")
        .find({
          query: {
            $select: [
              "id",
              "userId",
              "teacherId",
              "courseId",
              "startAt",
              "finishAt",
              "isIndividual",
              "isHidden",
            ],
            userId: {
              $in: [userId],
            },
						$limit: individualLessonsCount
          }
        })
        .then((result) => result.data)
    );

    yield put(actions.loadUserSubscriptionFromApiSuccess(userSubscription));
    yield put(actions.loadIndividualLessonFromApi(individualLesson));
    yield put(actions.loadStreamsFromApi(user.id));
    yield put(actions.loadFromApiSuccess(user));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadUserFromApi({ payload }) {
  try {
    const { userId } = payload;

    const user = yield call(() => api.service(COLLECTION_NAME).get(userId));

    yield put(actions.loadFromApiSuccess(user));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadStreamsFromApi({ payload }) {
  try {
    const { userId } = payload;

    const streamUsers = yield call(() =>
      api.service("stream-user").find({
        query: {
          userId,
          $select: ["id", "streamId", "userId"],
          $limit: 100,
          include: [
            {
              model: "stream",
              as: "stream",
              attributes: ["id", "num"],
              include: [
                {
                  model: "course",
                  as: "course",
                  attributes: ["name"],
                  include: [
                    {
                      model: "subject",
                      as: "subject",
                      attributes: ["id", "name"],
                    },
                  ],
                },
                {
                  model: "lesson",
                  as: "lessons",
                  required: false,
                  attributes: [
                    "id",
                    "startAt",
                    "finishAt",
                    "isHidden",
                    "isIndividual",
                  ],
                  where: {
                    deleted: false,
                  },
                  sortBy: [["startAt", "ASC"]],
                  include: [
                    {
                      model: "exercise",
                      as: "exercise",
                      required: false,
                      where: {
                        deleted: false,
                      },
                      attributes: [
                        'id',
                        "name",
                        "duration",
                        "hasTest",
                        "hasHomework",
                      ],
                    },
                    {
                      model: "visit",
                      as: "visits",
                      where: {
                        userId,
                      },
                      limit: 1,
                      attributes: ["id", "createdAt"],
                    },
                    {
                      model: "student_homework",
                      as: "studentHomeworks",
                      limit: 1,
                      where: {
                        studentId: userId,
                      },
                    },
                    {
                      model: "student_test",
                      as: "studentTests",
                      where: {
                        studentId: userId,
                      },
                      limit: 1,
                      attributes: [
                        "id",
                        "submitTime",
                        "score",
                        "tasksCount",
                        "taskCompleted",
                        "teacherComment",
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      })
    );

    const streamIds = _.map(streamUsers.data, (value) => value.streamId);

    const nextLessonsParams = {
      query: {
        $select: ["id", "startAt"],
        streamId: {
          $in: streamIds,
        },
        isHidden: false,
        startAt: {
          $gt: moment(),
        },
        $limit: 3,
        $sort: {
          startAt: 1,
        },
        include: [
          {
            model: "exercise",
            as: "exercise",
            required: true,
            where: {
              deleted: false,
            },
            attributes: ["id", "name", "duration"],
          },
          {
            model: "stream",
            as: "stream",
            required: true,
            where: {
              deleted: false,
            },
            attributes: ["id", "num"],
            include: [
              {
                model: "course",
                as: "course",
                required: true,
                where: {
                  deleted: false,
                },
                attributes: ["name"],
                include: [
                  {
                    model: "subject",
                    as: "subject",
                    required: true,
                    where: {
                      deleted: false,
                    },
                    attributes: ["name"],
                  },
                ],
              },
            ],
          },
        ],
      },
    };

    const nextLessons = yield call(() =>
      api.service("lesson").find(nextLessonsParams)
    );

    yield put(
      actions.loadStreamsFromApiSuccess(
        streamUsers.data,
        nextLessons.data,
        streamUsers.total
      )
    );
  } catch (error) {
    console.log(error);
    yield put(actions.loadStreamsFromApiError(error));
  }
}

function* loadCoursesFromApi() {
  try {
    const params = {
      query: {
        $select: ["id", "name", "subjectId"],
        include: [
          {
            model: "subject",
            as: "subject",
            attributes: ["name"],
          },
          {
            model: "stream",
            as: "streams",
            attributes: ["id", "num"],
            include: [
              {
                model: "lesson",
                as: "lessons",
                attributes: ["startAt", "isIndividual"],
                orderBy: [["startAt", "ASC"]],
              },
            ],
          },
        ],
      },
    };

    const courses = yield call(() => api.service("course").find(params));

    yield put(actions.loadCoursesFromApiSuccess(courses.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* saveToApi({ payload }) {
  try {
    const { userId, data } = payload;

    const result = yield call(() =>
      api.service(COLLECTION_NAME).patch(userId, data)
    );

    yield put(actions.saveToApiSuccess(result));
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

function* saveIndividualLessonToApi({ payload }) {
  const { item } = payload;

  try {
    const lessonParams = {
      userId: item?.userId,
      teacherId: item?.teacherId,
      startAt: item?.startTime ? item?.startTime : item?.startAt,
      finishAt: item?.finishTime,
      exerciseId: item?.exerciseId,
      courseId: item?.courseId,
    };

    let itemResponse = {};

    if (item.id) {
      itemResponse = yield call(() =>
        api.service("lesson-individual").patch(item.id, lessonParams)
      );
    } else {
      itemResponse = yield call(() =>
        api.service("lesson-individual").create(lessonParams)
      );
    }

		const config = yield call(() => api
			.service("configuration")
			.get("mail-notifications")
			.then((response) => response.config)
		);

		if (config.individualLesson) {
			yield call(() => api.service("mailer").create({
				to: item?.userEmail,
				template: "individual-lesson",
				userId: "-",
				courseName: item?.courseName,
				date: moment(item?.startTime).format("D MMMM YYYY"),
				time: moment(item?.startTime).format("HH:mm"),
				exerciseId: itemResponse?.id,
			}));
		}

    yield put(actions.saveIndividualLessonToApiSuccess(itemResponse));
  } catch (error) {
    console.log(error);
  }
}

function* deleteStreamUser({ payload }) {
  try {
    const { streamUserId, userId } = payload;

    yield call(() =>
      api.service("stream-user").patch(streamUserId, { deleted: true })
    );
    yield put(actions.loadStreamsFromApi(userId));
  } catch (error) {
    console.log(error);
    yield put(actions.deleteStreamUserError(error));
  }
}

function* toggleLessonHidden({ payload }) {
  try {
    const user = yield select(getUser);

    const { lessonId, params } = payload;

    yield call(() => api.service("lesson").patch(lessonId, params));
    yield put(actions.loadStreamsFromApi(user.id));
  } catch (error) {
    console.log(error);
    yield put(actions.toggleLessonHiddenError(error));
  }
}

function* toggleIndividualLessonHidden({ payload }) {
  try {
    const user = yield select(getUser);

    const { lessonId, params } = payload;

    yield call(() => api.service("lesson-individual").patch(lessonId, params));
    const individualLesson = yield call(() =>
      api
        .service("lesson-individual")
        .find({
          query: {
            $select: [
              "id",
              "userId",
              "teacherId",
              "courseId",
              "startAt",
              "finishAt",
              "isIndividual",
              "isHidden",
            ],
            userId: {
              $in: [user.id],
            },
          },
        })
        .then((result) => result.data)
    );

    yield put(actions.loadIndividualLessonFromApi(individualLesson));
  } catch (error) {
    console.log(error);
    yield put(actions.toggleLessonHiddenError(error));
  }
}

function* createStreamUser({ payload }) {
  try {
    const { streamId, userId } = payload;

    const params = {
      streamId,
      userId,
    };

    yield call(() => api.service("stream-user").create(params));
    yield put(actions.loadStreamsFromApi(userId));
  } catch (error) {
    console.log(error);
    yield put(actions.createStreamUserError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),
    takeEvery(actions.LOAD_USER_FROM_API, loadUserFromApi),
    takeEvery(actions.LOAD_STREAMS_FROM_API, loadStreamsFromApi),
    takeEvery(actions.LOAD_COURSES_FROM_API, loadCoursesFromApi),
    takeEvery(actions.SAVE_TO_API, saveToApi),
    takeEvery(actions.SAVE_INDIVIDUAL_LESSON_TO_API, saveIndividualLessonToApi),
    takeEvery(actions.DELETE_STREAM_USER, deleteStreamUser),
    takeEvery(actions.CREATE_STREAM_USER, createStreamUser),
    takeEvery(actions.TOGGLE_LESSON_HIDDEN, toggleLessonHidden),
    takeEvery(
      actions.TOGGLE_INDIVIDUAL_LESSON_HIDDEN,
      toggleIndividualLessonHidden
    ),
  ]);
}
