import { combineReducers } from 'redux';
import settings from './settings/reducer';
import landingSettings from './landingSettings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import todoApp from './todo/reducer';
import chatApp from './chat/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';
import course from './course/reducer';
import courseVault from './courseVault/reducer';
import mediaVault from './mediaVault/reducer';
import admin from './admin/reducer';
import adminVault from './adminVault/reducer';
import user from './user/reducer';
import userVault from './userVault/reducer';
import exercise from './exercise/reducer';
import exerciseVault from './exerciseVault/reducer';
import subject from './subject/reducer';
import subjectVault from './subjectVault/reducer';
import subscription from './subscription/reducer';
import subscriptionVault from './subscriptionVault/reducer';
import achievement from './achievement/reducer';
import achievementVault from './achievementVault/reducer';
import level from './level/reducer';
import levelVault from './levelVault/reducer';
import lesson from './lesson/reducer';
import lessonVault from './lessonVault/reducer';
import note from './note/reducer';
import noteVault from './noteVault/reducer';
import stream from './stream/reducer';
import streamVault from './streamVault/reducer';
import operation from './operation/reducer';
import operationVault from './operationVault/reducer';
import teacher from './teacher/reducer';
import teacherVault from './teacherVault/reducer';
import student from './student/reducer';
import studentVault from './studentVault/reducer';
import studentProfile from './studentProfile/reducer';
import teacherProfile from './teacherProfile/reducer';
import newsletter from './newsletter/reducer';
import newsletterVault from './newsletterVault/reducer';
import registerOfPayments from './registerOfPayments/reducer';
import registerOfPaymentsVault from './registerOfPaymentsVault/reducer';
import configuration from './configuration/reducer';
import lessonChat from './lessonChat/reducer';
import lessonTeacherPage from './lessonTeacherPage/reducer';

const reducers = combineReducers({
  menu,
  settings,
  landingSettings,
  authUser,
  todoApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  course,
  courseVault,
  mediaVault,
  admin,
  adminVault,
  user,
  userVault,
  exercise,
  exerciseVault,
  subject,
  subjectVault,
  stream,
  streamVault,
  operation,
  operationVault,
  note,
  noteVault,
  level,
  levelVault,
  achievement,
  achievementVault,
  lesson,
  lessonVault,
  subscription,
  subscriptionVault,
  studentProfile,
  teacherProfile,
  newsletter,
  newsletterVault,
  teacher,
  teacherVault,
  student,
  lessonChat,
  lessonTeacherPage,
  studentVault,
  registerOfPayments,
  registerOfPaymentsVault,
  configuration,
});

export default reducers;
