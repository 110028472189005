const DOCUMENT = 'ADMIN_VAULT_';

const actions = {
  GET_ITEM: `${DOCUMENT}GET_ITEM`,
  GET_ITEM_SUCCESS: `${DOCUMENT}GET_ITEM_SUCCESS`,
  GET_ITEM_ERROR: `${DOCUMENT}GET_ITEM_ERROR`,

  getItem: (id) => {
    return {
      type: actions.GET_ITEM,
      payload: {
        id,
      },
    };
  },
  getItemSuccess: (item) => {
    return {
      type: actions.GET_ITEM_SUCCESS,
      payload: item,
    };
  },
  getItemError: () => {
    return {
      type: actions.GET_ITEM_ERROR,
    };
  },
};

export default actions;
