import moment from 'moment'

export const teacherStreamsInclude = (userId) => {
  return [
    {
      model: 'stream',
      as: 'stream',
      atrributes: ['id', 'num'],
      include: [
        {
          model: 'course',
          as: 'course',
          attributes: ['id', 'name'],

          include: [
            {
              model: 'subject',
              as: 'subject',
              attributes: ['id', 'name']
            }
          ]
        },
        {
          model: 'lesson',
          as: 'lessons',
          required: false,
          attributes: ['id', 'startAt', 'isHidden'],
          where: {
            deleted: false,
          },
          sortBy: [['startAt', 'ASC']],
          include: [
            {
              model: 'exercise',
              as: 'exercise',
              required: true,
              where: {
                deleted: false
              },
              attributes: ['name', 'duration'],
            },
          ]
        },
      ]
    }
  ]
}

export const teacherStreamsAllDataInclude = (userId) => {
  return [
    {
      model: 'stream',
      as: 'stream',
      atrributes: ['id', 'num', 'usersLimit'],
      include: [
        {
          model: 'course',
          as: 'course',
          attributes: ['id', 'name'],
          where: {
            deleted: false
          },
          include: [
            {
              model: 'subject',
              as: 'subject',
              attributes: ['id', 'name'],
              where: {
                deleted: false
              },
            }
          ]
        },
        {
          model: 'lesson',
          as: 'lessons',
          required: false,
          attributes: ['id', 'startAt', 'isHidden', 'teacherId'],
          where: {
            deleted: false,
          },
          sortBy: [['startAt', 'ASC']],
          include: [
            {
              model: 'exercise',
              as: 'exercise',
              required: true,
              where: {
                deleted: false
              },
              attributes: ['name', 'duration'],
            },
            {
              model: 'visit',
              as: 'visits',
              where: {
                userId: {
                  ne: userId
                }
              },
              limit: 100,
              orderBy: [['createdAt', 'DESC']],
              attributes: ['id', 'createdAt', 'userId'],
            },
            {
              model: 'student_homework',
              as: 'studentHomeworks',
              limit: 100,
              attributes: ['id', 'studentId', 'submitTime', 'score', 'teacherComment'],
            },
            {
              model: 'student_test',
              as: 'studentTests',
              limit: 100,
              attributes: ['id', 'submitTime', 'score', 'tasksCount', 'taskCompleted', 'teacherComment'],
            },
          ]
        },
      ]
    }
  ]
}

export const nextLessonsInclude = () => {
  return [
    {
      model: 'exercise',
      as: 'exercise',
      required: true,
      where: {
        deleted: false
      },
      attributes: ['name', 'duration'],
    },
    {
      model: 'stream',
      as: 'stream',
      required: true,
      where: {
        deleted: false
      },
      attributes: ['id', 'num'],
      include: [
        {
          model: 'course',
          as: 'course',
          required: true,
          where: {
            deleted: false
          },
          attributes: ['id', 'name'],
          include: [
            {
              model: 'subject',
              as: 'subject',
              required: true,
              where: {
                deleted: false
              },
              attributes: ['name'],
            }
          ]
        }
      ]
    },
  ]
}

export const homeworksInclude = () => {
  return [
    {
      model: 'user',
      as: 'student',
      attributes: ['firstname', 'lastname']
    },
		{
			model: "lesson_individual",
			as: "individualLesson",
			attributes: ["id", "startAt", "isIndividual"],
			include: [
				{
          model: "exercise",
          as: "exercise",
          required: false,
          where: {
            deleted: false,
          },
          attributes: ["name", "duration"],
        },
			]
		},
    {
      model: 'lesson',
      as: 'lesson',
      attributes: ['id', 'startAt'],
      include: [
        {
          model: 'exercise',
          as: 'exercise',
          required: true,
          where: {
            deleted: false
          },
          attributes: ['name', 'duration'],
        },
        {
          model: 'stream',
          as: 'stream',
          required: true,
          where: {
            deleted: false
          },
          attributes: ['num'],
          include: [
            {
              model: 'course',
              as: 'course',
              required: true,
              where: {
                deleted: false
              },
              attributes: ['id', 'name'],
              include: [
                {
                  model: 'subject',
                  as: 'subject',
                  required: true,
                  where: {
                    deleted: false
                  },
                  attributes: ['name'],
                }
              ]
            }
          ]
        },
      ]
    }
  ]
}

export const ownTeacherCourseInclude = (userId) => {
	return [
		{
			model: 'course',
			as: 'course',
			attributes: ['id', 'name'],
			where: {
				deleted: false
			},
			include: [
				{
					model: 'subject',
					as: 'subject',
					attributes: ['id', 'name'],
					where: {
						deleted: false
					},
				}
			]
		},
		{
			model: 'lesson',
			as: 'lessons',
			required: false,
			attributes: ['id', 'startAt', 'isHidden', 'teacherId'],
			where: {
				deleted: false,
			},
			sortBy: [['startAt', 'ASC']],
			include: [
				{
					model: 'exercise',
					as: 'exercise',
					required: true,
					where: {
						deleted: false
					},
					attributes: ['name', 'duration'],
				},
				{
					model: 'visit',
					as: 'visits',
					where: {
						userId: {
							ne: userId
						}
					},
					limit: 100,
					orderBy: [['createdAt', 'DESC']],
					attributes: ['id', 'createdAt', 'userId'],
				},
				{
					model: 'student_homework',
					as: 'studentHomeworks',
					limit: 100,
					attributes: ['id', 'studentId', 'submitTime', 'score', 'teacherComment'],
				},
				{
					model: 'student_test',
					as: 'studentTests',
					limit: 100,
					attributes: ['id', 'submitTime', 'score', 'tasksCount', 'taskCompleted', 'teacherComment'],
				},
			]
		},
	];
};