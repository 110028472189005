import api from 'api';

// eslint-disable-next-line import/no-cycle
import store from 'redux/store';

import actions from './actions';
import streamActions from '../stream/actions';

api.service('stream').on('created', (item) => {
  store.dispatch(actions.getItemSuccess(item));
});

api.service('stream').on('patched', () => {
  store.dispatch(streamActions.loadFromApi());
});

api.service('stream').on('updated', () => {
  store.dispatch(streamActions.loadFromApi());
});

api.service('stream').on('removed', (item) => {
  store.dispatch(actions.getItemSuccess({ ...item, deleted: true }));
});

export default api;
