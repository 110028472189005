const DOCUMENT = 'CONFIGURATION';

const actions = {
  LOAD_FROM_API: `${DOCUMENT}LOAD_FROM_API`,
  LOAD_FROM_API_SUCCESS: `${DOCUMENT}LOAD_FROM_API_SUCCESS`,
  LOAD_FROM_API_ERROR: `${DOCUMENT}LOAD_FROM_API_ERROR`,

  loadFromApi: () => {
    return {
      type: actions.LOAD_FROM_API,
    };
  },

  loadFromApiSuccess: ({ data }) => ({
    type: actions.LOAD_FROM_API_SUCCESS,
    payload: {
      data,
    },
  }),

  loadFromApiError: (error) => ({
    type: actions.LOAD_FROM_API_ERROR,
    payload: {
      error,
    },
  }),
};

export default actions;
