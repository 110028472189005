import moment from "moment";

export const studentStreamsInclude = (userId) => {
  return [
    {
      model: "stream",
      as: "stream",
      attributes: ["id", "num", "name"],
      include: [
        {
          model: "course",
          as: "course",
          attributes: ["id", "name"],
          include: [
            {
              model: "subject",
              as: "subject",
              attributes: ["id", "name"],
            },
          ],
        },
        {
          model: "lesson",
          as: "lessons",
          required: false,
          attributes: [
            "id",
            "startAt",
            "finishAt",
            "isHidden",
            "isIndividual",
            "teacherId",
            "streamId",
          ],
          where: {
            deleted: false,
          },
					separate: true,
          order: [["startAt", "ASC"]],
          include: [
            {
              model: "exercise",
              as: "exercise",
              required: false,
              where: {
                deleted: false,
              },
              attributes: ["name", "duration", "hasTest", "hasHomework"],
            },
            {
              model: "visit",
              as: "visits",
              where: {
                userId,
              },
              limit: 1,
              attributes: ["id", "createdAt"],
            },
            {
              model: "student_homework",
              as: "studentHomeworks",
              limit: 1,
              where: {
                studentId: userId,
              },
            },
            {
              model: "student_test",
              as: "studentTests",
              where: {
                studentId: userId,
              },
              limit: 1,
              attributes: [
                "id",
                "submitTime",
                "score",
                "tasksCount",
                "taskCompleted",
                "teacherComment",
              ],
            },
          ],
        },
      ],
    },
  ];
};
