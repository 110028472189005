export const studentsStreamsInclude = (userId) => {
  return [
    {
      model: "user",
      as: "user",
      include: [
        {
          model: "prefered_day",
          as: "preferedDays",
          attributes: ["id", "dayNum"],
        },
      ],
    },
  ];
};

export const streamLessonInclude = (streamId) => {
  return [
    {
      model: "exercise",
      as: "exercise",
      required: true,
      where: {
        deleted: false,
      },
      attributes: ["name", "duration"],
    },
  ];
};
