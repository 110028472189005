export const studentsStreamsInclude = (userId) => {
  return [
    {
      model: 'stream',
      as: 'stream',
      atrributes: ['id', 'num'],
      include: [
        {
          model: 'course',
          as: 'course',
          attributes: ['id', 'name'],
          where: {
            deleted: false,
          },
          include: [
            {
              model: 'subject',
              as: 'subject',
              attributes: ['id', 'name'],
              where: {
                deleted: false,
              },
            },
          ],
        },
        {
          model: 'lesson',
          as: 'lessons',
          required: false,
          attributes: ['id', 'startAt', 'isHidden'],
          where: {
            deleted: false,
          },
          sortBy: [['startAt', 'ASC']],
          include: [
            {
              model: 'exercise',
              as: 'exercise',
              required: true,
              where: {
                deleted: false,
              },
              attributes: ['name', 'duration'],
            },
          ],
        },
      ],
    },
  ];
};
