import { all, takeEvery, put, call, select } from "redux-saga/effects";
import api from "api";
import _ from "lodash";
import actions from "./actions";

const COLLECTION_NAME = "landing";

function* loadFromApi() {
  try {
    const params = {
      query: {
        $select: ["id", "module", "service", "list"],
      },
    };
    const data = yield call(() => api.service(COLLECTION_NAME).find(params));
    yield put(actions.loadFromApiSuccess(data.data));
  } catch (error) {
    console.log(error);
  }
}

function* loadCoursesFromApi({ payload }) {
  try {
    const data = yield call(() =>
      api
        .service("course")
        .find({
          query: {
            $select: ["id", "subjectId", "name"],
            include: [
              {
                model: "subject",
                as: "subject",
                attributes: ["id", "name"],
              },
            ],
          },
        })
        .then((res) => res.data)
    );

    const streamTotal = yield call(() =>
      api
        .service("stream")
        .find({
          query: {
            $select: ["id"],
          },
        })
        .then((res) => res.total)
    );

    const streams = yield call(() =>
      api
        .service("stream")
        .find({
          query: {
            $select: ["id", "courseId", "name"],
            $limit: streamTotal,
          },
        })
        .then((res) => res.data)
    );

    _.map(data, (item) => {
      item.streams = [];
      _.map(streams, (stream) =>
        stream.courseId === item.id ? item.streams.push(stream) : null
      );
    });

    yield put(actions.loadCoursesFromApiSuccess(data));
  } catch (error) {
    console.log(error);
  }
}

function* loadSubjectsFromApi({ payload }) {
  try {
    const params = {
      query: {
        $select: ["id", "name"],
      },
    };

    const data = yield call(() => api.service("subject").find(params));

    const courses = yield all(
      data.data.map((el) =>
        call(() =>
          api.service("course").find({
            query: {
              $select: ["id", "name"],
              subjectId: el.id,
            },
          })
        )
      )
    );

    const subjects = _.map(data.data, (item, index) => ({
      ...item,
      courses: courses[index].data,
    }));

    yield put(actions.loadSubjectsFromApiSuccess(subjects));
  } catch (error) {
    console.log(error);
  }
}

function* saveToApi({ payload }) {
  const { item } = payload;
  console.log("itemSAGA", item);
  try {
    const coursesModuleParams = {
      list: item.list,
    };

    const popularCoursesModuleParams = {
      list: item.listOfPopular,
    };

    const coursesItemResponse = yield call(() =>
      api
        .service(COLLECTION_NAME)
        .patch(item.coursesModuleId, coursesModuleParams)
    );

    const popularCoursesItemResponse = yield call(() =>
      api
        .service(COLLECTION_NAME)
        .patch(item.moduleOfPopular, popularCoursesModuleParams)
    );

    yield put(
      actions.saveToApiSuccess(coursesItemResponse, popularCoursesItemResponse)
    );
  } catch (error) {
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_FROM_API, loadFromApi)]);
  yield all([takeEvery(actions.LOAD_COURSES_FROM_API, loadCoursesFromApi)]);
  yield all([takeEvery(actions.LOAD_SUBJECTS_FROM_API, loadSubjectsFromApi)]);
  yield all([takeEvery(actions.SAVE_TO_API, saveToApi)]);
}
