const DOCUMENT = "STUDENT_PROFILE_";

const actions = {
  LOAD_FROM_API: `${DOCUMENT}LOAD_FROM_API`,
  LOAD_FROM_API_SUCCESS: `${DOCUMENT}LOAD_FROM_API_SUCCESS`,
  LOAD_FROM_API_ERROR: `${DOCUMENT}LOAD_FROM_API_ERROR`,

  LOAD_USER_FROM_API: `${DOCUMENT}LOAD_USER_FROM_API`,
  LOAD_USER_FROM_API_SUCCESS: `${DOCUMENT}LOAD_USER_FROM_API_SUCCESS`,
  LOAD_USER_FROM_API_ERROR: `${DOCUMENT}LOAD_USER_FROM_API_ERROR`,

  LOAD_STREAMS_FROM_API: `${DOCUMENT}LOAD_STREAMS_FROM_API`,
  LOAD_STREAMS_FROM_API_SUCCESS: `${DOCUMENT}LOAD_STREAMS_FROM_API_SUCCESS`,
  LOAD_STREAMS_FROM_API_ERROR: `${DOCUMENT}LOAD_STREAMS_FROM_API_ERROR`,

  LOAD_COURSES_FROM_API: `${DOCUMENT}LOAD_COURSES_FROM_API`,
  LOAD_COURSES_FROM_API_SUCCESS: `${DOCUMENT}LOAD_COURSES_FROM_API_SUCCESS`,
  LOAD_COURSES_FROM_API_ERROR: `${DOCUMENT}LOAD_COURSES_FROM_API_ERROR`,

  LOAD_INDIVIDUAL_LESSON_FROM_API: `${DOCUMENT}LOAD_INDIVIDUAL_LESSON_FROM_API`,

  SAVE_INDIVIDUAL_LESSON_TO_API: `${DOCUMENT}SAVE_INDIVIDUAL_LESSON_TO_API`,
  SAVE_INDIVIDUAL_LESSON_TO_API_SUCCESS: `${DOCUMENT}SAVE_INDIVIDUAL_LESSON_TO_API_SUCCESS`,
  SAVE_INDIVIDUAL_LESSON_TO_API_ERROR: `${DOCUMENT}SAVE_INDIVIDUAL_LESSON_TO_API_ERROR`,

  SAVE_TO_API: `${DOCUMENT}SAVE_TO_API`,
  SAVE_TO_API_SUCCESS: `${DOCUMENT}SAVE_TO_API_SUCCESS`,
  SAVE_TO_API_ERROR: `${DOCUMENT}SAVE_TO_API_ERROR`,

  DELETE_STREAM_USER: `${DOCUMENT}DELETE_STREAM_USER`,
  DELETE_STREAM_USER_ERROR: `${DOCUMENT}DELETE_STREAM_USER_ERROR`,

  TOGGLE_LESSON_HIDDEN: `${DOCUMENT}TOGGLE_LESSON_HIDDEN`,
  TOGGLE_INDIVIDUAL_LESSON_HIDDEN: `${DOCUMENT}TOGGLE_INDIVIDUAL_LESSON_HIDDEN`,
  TOGGLE_LESSON_HIDDEN_ERROR: `${DOCUMENT}TOGGLE_LESSON_HIDDEN_ERROR`,

  CREATE_STREAM_USER: `${DOCUMENT}CREATE_STREAM_USER`,
  CREATE_STREAM_USER_ERROR: `${DOCUMENT}CREATE_STREAM_USER_ERROR`,
  
  LOAD_USER_SUBSCRIPTION_FROM_API: `${DOCUMENT}LOAD_USER_SUBSCRIPTION_FROM_API`,
  LOAD_USER_SUBSCRIPTION_FROM_API_SUCCESS: `${DOCUMENT}LOAD_USER_SUBSCRIPTION_FROM_API_SUCCESS`,

  loadFromApi: (id) => {
    return {
      type: actions.LOAD_FROM_API,
      payload: {
        userId: id,
      },
    };
  },
  
  loadUserSubscriptionFromApi: () => {
    return {
      type: actions.LOAD_USER_SUBSCRIPTION_FROM_API,
    };
  },

  loadUserSubscriptionFromApiSuccess: (userSubscription) => ({
    type: actions.LOAD_USER_SUBSCRIPTION_FROM_API_SUCCESS,
    payload: {
      userSubscription,
    },
  }),

  loadFromApiSuccess: (user) => ({
    type: actions.LOAD_FROM_API_SUCCESS,
    payload: {
      user,
    },
  }),

  loadFromApiError: (error) => ({
    type: actions.LOAD_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  loadUserFromApi: (id) => {
    return {
      type: actions.LOAD_USER_FROM_API,
      payload: {
        userId: id,
      },
    };
  },

  loadUserFromApiSuccess: (user) => ({
    type: actions.LOAD_USER_FROM_API_SUCCESS,
    payload: {
      user,
    },
  }),

  loadUserFromApiError: (error) => ({
    type: actions.LOAD_USER_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  loadStreamsFromApi: (id) => {
    return {
      type: actions.LOAD_STREAMS_FROM_API,
      payload: {
        userId: id,
      },
    };
  },

  loadStreamsFromApiSuccess: (streamUsers, nextLessons, streamTotalItems) => ({
    type: actions.LOAD_STREAMS_FROM_API_SUCCESS,
    payload: {
      streamUsers,
      nextLessons,
      streamTotalItems,
    },
  }),

  loadStreamsFromApiError: (error) => ({
    type: actions.LOAD_STREAMS_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  loadCoursesFromApi: () => {
    return {
      type: actions.LOAD_COURSES_FROM_API,
    };
  },

  loadCoursesFromApiSuccess: (courses) => ({
    type: actions.LOAD_COURSES_FROM_API_SUCCESS,
    payload: {
      courses,
    },
  }),

  loadCoursesFromApiError: (error) => ({
    type: actions.LOAD_COURSES_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  loadIndividualLessonFromApi: (individualLessons) => ({
    type: actions.LOAD_INDIVIDUAL_LESSON_FROM_API,
    payload: {
      individualLessons,
    },
  }),

  saveToApi: (id, data) => {
    return {
      type: actions.SAVE_TO_API,
      payload: {
        userId: id,
        data,
      },
    };
  },

  saveToApiSuccess: (data) => ({
    type: actions.SAVE_TO_API_SUCCESS,
    payload: {
      data,
    },
  }),

  saveToApiError: (error) => ({
    type: actions.SAVE_TO_API_ERROR,
    payload: {
      error,
    },
  }),

  saveIndividualLessonToApi: (item) => ({
    type: actions.SAVE_INDIVIDUAL_LESSON_TO_API,
    payload: {
      item,
    },
  }),

  saveIndividualLessonToApiSuccess: (item) => ({
    type: actions.SAVE_INDIVIDUAL_LESSON_TO_API_SUCCESS,
    payload: {
      item,
    },
  }),

  saveIndividualLessonToApiError: (error) => ({
    type: actions.SAVE_INDIVIDUAL_LESSON_TO_API_ERROR,
    payload: {
      error,
    },
  }),

  deleteStreamUser: (streamUserId, userId) => {
    return {
      type: actions.DELETE_STREAM_USER,
      payload: {
        streamUserId,
        userId,
      },
    };
  },

  deleteStreamUserError: (error) => ({
    type: actions.DELETE_STREAM_USER_ERROR,
    payload: {
      error,
    },
  }),

  toggleLessonHidden: (lessonId, params) => {
    return {
      type: actions.TOGGLE_LESSON_HIDDEN,
      payload: {
        lessonId,
        params,
      },
    };
  },

  toggleIndividualLessonHidden: (lessonId, params) => {
    return {
      type: actions.TOGGLE_INDIVIDUAL_LESSON_HIDDEN,
      payload: {
        lessonId,
        params,
      },
    };
  },

  toggleLessonHiddenError: (error) => ({
    type: actions.TOGGLE_LESSON_HIDDEN_ERROR,
    payload: {
      error,
    },
  }),

  createStreamUser: (streamId, userId) => {
    return {
      type: actions.CREATE_STREAM_USER,
      payload: {
        streamId,
        userId,
      },
    };
  },

  createStreamUserError: (error) => ({
    type: actions.CREATE_STREAM_USER_ERROR,
    payload: {
      error,
    },
  }),
};

export default actions;
