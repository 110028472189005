import { all, takeEvery, put, call, select } from "redux-saga/effects";
import api from "api";

import moment from "moment";
import _ from "lodash";
import actions from "./actions";

const { paramsForServer } = require("feathers-hooks-common");

const COLLECTION_NAME = "operation"; // change your collection

const getPage = (state) => state.registerOfPayments.page;
const getItemsPerPage = (state) => state.registerOfPayments.itemsPerPage;
const getFilters = (state) => state.registerOfPayments.filters;

function* loadFromApi() {
  try {
    const page = yield select(getPage);
    const itemsPerPage = yield select(getItemsPerPage);
    const filters = yield select(getFilters);

    const params = paramsForServer({
      query: {
        $select: ["id"],
        $limit: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $sort: {
          time: -1,
        },
        include: [
          {
            model: "operation_type",
            as: "type",
            required: true,
            where: {},
          },
          {
            model: "user",
            as: "user",
            required: true,
            where: {},
          },
        ],
      },
      getAmounts: true,
    });

    const {
      search: { searchValue, searchType },
      after,
      before,
      hasComment,
      admins,
      users,
      types,
    } = filters;

    if (searchValue) {
      if (searchType === "type_id") {
        params.query.include[0].where.name = { iLike: `%${searchValue}%` };
      } else if (searchType === "userName") {
        const searchValueWordsArray = _.compact(
          _.split(_.trim(searchValue), " ")
        );

        params.query.include[1].where = {
          or:
            searchValueWordsArray.length === 1
              ? [
                  { firstname: { iLike: `%${searchValueWordsArray[0]}%` } },
                  { lastname: { iLike: `%${searchValueWordsArray[0]}%` } },
                  { key: { iLike: `%${searchValueWordsArray[0]}%` } },
                ]
              : [
                  { firstname: { iLike: `%${searchValueWordsArray[0]}%` } },
                  {
                    lastname: {
                      iLike: `%${searchValueWordsArray.slice(1).join(" ")}%`,
                    },
                  },
                ],
        };
      } else {
        params.query[searchType] = { $iLike: `%${searchValue}%` };
      }
    }
    if (after)
      params.query.time = {
        ...params.query.time,
        $gte: after,
      };
    if (before)
      params.query.time = {
        ...params.query.time,
        $lte: moment(before).add(1, "day"),
      };
    if (!_.isEmpty(admins)) {
      params.query.adminId = {
        $in: _.map(admins, (admin) => admin.value),
      };
    }
    if (!_.isEmpty(users)) {
      params.query.userId = {
        $in: _.map(users, (user) => user.value),
      };
    }
    if (hasComment)
      params.query.comment = {
        $ne: null,
      };
    if (!_.isEmpty(types))
      params.query.typeId = {
        $in: _.map(types, (type) => type.value),
      };

    const { data, total, amountNegative, amountPositive } = yield call(() =>
      api.service(COLLECTION_NAME).find(params)
    );

    yield put(
      actions.loadFromApiSuccess({
        data,
        totalItems: total,
        amountPositive,
        amountNegative,
      })
    );
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadOperationTypesFromApi() {
  try {
    const operationTypesParams = {
      query: {
        $select: ["id", "name"],
        $sort: {
          createdAt: 1,
        },
      },
    };

    const operationTypes = yield call(() =>
      api.service("operation-type").find(operationTypesParams)
    );

    const adminsParams = {
      query: {
        typeId: {
          $in: ["IN_ADMIN", "OUT_ADMIN"],
        },
        adminName: {
          $ne: null,
        },
        $select: ["adminName"],
        $limit: 100,
      },
    };

    const adminsOperations = yield call(() =>
      api.service("operation").find(adminsParams)
    );
    const admins = _.uniq(
      _.map(adminsOperations.data, (operation) => operation.adminName)
    );

    yield put(
      actions.loadOperationTypesFromApiSuccess(operationTypes.data, admins)
    );
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* createAdminOperation({ payload }) {
  try {
    const { data } = payload;

    yield call(() => api.service(COLLECTION_NAME).create(data));

    yield put(actions.setPage(1));
    yield put(actions.loadOperationTypesFromApi());
    // yield put(studentActions.loadUserFromApi(data.userId));
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),
    takeEvery(actions.LOAD_OPERATION_TYPES_FROM_API, loadOperationTypesFromApi),
    takeEvery(actions.SET_PAGE, loadFromApi),
    takeEvery(actions.SET_ITEMS_PER_PAGE, loadFromApi),
    takeEvery(actions.SET_FILTER, loadFromApi),
    takeEvery(actions.CREATE_ADMIN_OPERATION, createAdminOperation),
  ]);
}
