import { all, takeEvery, put, call, select } from "redux-saga/effects";
import api from "api";
import actions from "./actions";
import courseVaultActions from '../courseVault/actions';
import subjectActions from "redux/subject/actions";
import _ from "lodash";

const COLLECTION_NAME = "course"; // change your collection

const getPage = (state) => state.course.page;
const getItemsPerPage = (state) => state.course.itemsPerPage;
const getOrderBy = (state) => state.course.orderBy;
const getOrderByDirection = (state) => state.course.orderByDirection;
const getFilters = (state) => state.course.filters;

function* loadFromApi({ payload }) {
  try {
    const page = yield select(getPage);
    const itemsPerPage = yield select(getItemsPerPage);
    const orderBy = yield select(getOrderBy);
    const orderByDirection = yield select(getOrderByDirection);
    const filters = yield select(getFilters);

    const params = {
      query: {
        subjectId: payload.subjectId ? payload.subjectId : undefined,
        $select: ["id", "subjectId", "name"],
        $or: [
          {
            name: {
              $iLike: `%${filters.search}%`,
            },
          },
          {
            description: {
              $iLike: `%${filters.search}%`,
            },
          },
        ],
        $limit: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $sort: {
          [orderBy]: orderByDirection,
        },
      },
    };

    const data = yield call(() => api.service(COLLECTION_NAME).find(params));

    yield put(actions.loadFromApiSuccess(data.data, data.total));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadExercisesFromApi({ payload }) {
  try {
    const { courseId } = payload;

    const orderBy = "orderBy";
    const orderByDirection = 1;

    const params = {
      query: {
        courseId,
        $sort: {
          [orderBy]: orderByDirection,
        },
        $limit: 50,
      },
    };

    const data = yield call(() => api.service("exercise").find(params));

    yield put(actions.loadExercisesFromApiSuccess(data.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* removeFromApi({ payload }) {
  try {
    const data = yield call(() =>
      api.service(COLLECTION_NAME).remove(payload.id)
    );

    yield put(actions.removeFromApiSuccess(data));
  } catch (error) {
    console.log("REMOVE CALL ERROR");

    console.log(error);
    yield put(actions.removeFromApiError(error));
  }
}

function* saveToApi({ payload }) {
  const { item, subjectId, exercises } = payload;

  try {
    const newMedias = [];

    item.courseMedias.forEach((courseMedia, index) => {
      courseMedia.orderBy = index;
      newMedias.push(courseMedia);
    });

    const params = {
      name: item.name,
      description: item.description,
      imageId: item.imageId,
      minAge: item.minAge,
      maxAge: item.maxAge,
      isDisabled: item.isDisabled,
      courseMedias: newMedias,
    };

    let itemResponse = {};

    if (item.id) {
      itemResponse = yield call(() =>
        api.service(COLLECTION_NAME).patch(item.id, params)
      );
    } else {
      itemResponse = yield call(() =>
        api.service(COLLECTION_NAME).create({
          ...params,
          subjectId,
        })
      );
      itemResponse = yield call(() =>
        api.service(COLLECTION_NAME).patch(itemResponse.id, params)
      );
    }

    yield all(
      _.map(exercises, (exercise, index) => {
          if(exercise.id){
            return api.service("exercise").patch(exercise.id, { orderBy: index })
          }
        }
      )
    );

    yield put(courseVaultActions.getItem(itemResponse?.id));

    yield put(actions.saveToApiSuccess(itemResponse));
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),
    takeEvery(actions.LOAD_EXERCISES_FROM_API, loadExercisesFromApi),
    takeEvery(actions.SET_PAGE, loadFromApi),
    takeEvery(actions.SET_ITEMS_PER_PAGE, loadFromApi),
    takeEvery(actions.SET_ORDER_BY, loadFromApi),
    takeEvery(actions.SET_ORDER_BY_DIRECTION, loadFromApi),
    takeEvery(actions.SET_FILTER, loadFromApi),
    takeEvery(actions.SAVE_TO_API, saveToApi),
    takeEvery(actions.REMOVE_FROM_API, removeFromApi),
  ]);
}
