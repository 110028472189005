import actions from './actions';

const initState = {};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.GET_ITEM_SUCCESS:
      return {
        ...state,
        [payload.id]: payload, // TODO: Merging maybe?
      };
    case actions.GET_ITEM_ERROR:
    case actions.GET_ITEM:
    default:
      return state;
  }
}
