import { all, takeEvery, put, call, select } from "redux-saga/effects";
import actions from "./actions";
import api from "api";
import _ from "lodash";
import moment from "moment";

import {
  teacherStreamsInclude,
  nextLessonsInclude,
  homeworksInclude,
  teacherStreamsAllDataInclude,
	ownTeacherCourseInclude,
} from "./includes";

const COLLECTION_NAME = "user";

const getUserId = (state) => state.teacherProfile.user.id;

function* loadFromApi({ payload }) {
  try {
    const { userId } = payload;

    const user = yield call(() => api.service(COLLECTION_NAME).get(userId));

    yield put(actions.loadNextLessonsFromApi(user.id));
    yield put(actions.loadStreamsFromApi(user.id));
    yield put(actions.loadFromApiSuccess(user));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadUserFromApi({ payload }) {
  try {
    const { userId } = payload;

    const user = yield call(() => api.service(COLLECTION_NAME).get(userId));

    yield put(actions.loadFromApiSuccess(user));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadNextLessonsFromApi({ payload }) {
  try {
    const { userId } = payload;

    const streamTotalItems = yield call(() =>
      api
        .service("stream-user")
        .find({
          query: {
            userId,
          },
        })
        .then((res) => res.total)
    );

    const streamUsers = yield call(() =>
      api.service("stream-user").find({
        query: {
          userId,
          $select: ["streamId", "userId"],
          $limit: streamTotalItems,
        },
      })
    );

    const streamIds = _.map(streamUsers.data, (value) => value.streamId);

    const nextLessonsParams = {
      query: {
        $select: ["id", "startAt"],
        $or: {
					streamId: {
						$in: streamIds,
					},
					teacherId: userId
				},
        isHidden: false,
        startAt: {
          $gt: moment(),
        },
        $limit: 3,
        $sort: {
          startAt: 1,
        },
        include: nextLessonsInclude(),
      },
    };

    const nextLessons = yield call(() =>
      api.service("lesson").find(nextLessonsParams)
    );

    yield put(actions.loadHomeworksFromApi(userId));
		yield put(actions.loadOwnTeacherLessons());
    yield put(actions.loadNextLessonsFromApiSuccess(nextLessons.data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadHomeworksFromApi({ payload }) {
  try {
    const { userId } = payload;

		const teacherLessonsTotalItems = (yield call(() =>
      api.service('lesson').find({
        query: {
          teacherId: userId,
          startAt: {
            $gt: moment().subtract(370, 'hours').format(),
            $lt: moment().format(),
          },
          $limit: 0,
        },
      })
    )).total;

    const teacherLessons = (yield call(() =>
      api.service('lesson').find({
        query: {
          $select: ['id', 'teacherId', 'startAt'],
          teacherId: userId,
          startAt: {
            $gt: moment().subtract(370, 'hours').format(),
            $lt: moment().format(),
          },
          $limit: teacherLessonsTotalItems,
        },
      })
    )).data;

    const teacherLessonsIds = teacherLessons.map(
      (teacherLesson) => teacherLesson.id
    );

    const teacherIndividualLessonsTotalItems = (yield call(() =>
      api.service('lesson-individual').find({
        query: {
          teacherId: userId,
          startAt: {
            $gt: moment().subtract(370, 'hours').format(),
            $lt: moment().format(),
          },
          $limit: 0,
        },
      })
    )).total;

    const teacherIndividualLessons = (yield call(() =>
      api.service('lesson-individual').find({
        query: {
          $select: ['id', 'teacherId', 'startAt'],
          teacherId: userId,
          startAt: {
            $gt: moment().subtract(370, 'hours').format(),
            $lt: moment().format(),
          },
          $limit: teacherIndividualLessonsTotalItems,
        },
      })
    )).data;

		const teacherIndividualLessonsIds = teacherIndividualLessons.map((teacherIndividualLesson) => teacherIndividualLesson.id);

    const homeworks = yield call(() =>
      api.service("student-homework").find({
        query: {
					$or: {
						lessonId: {
							$in: teacherLessonsIds
						},
						lessonIndividualId: {
							$in: teacherIndividualLessonsIds
						},
					},
          verifiedAt: null,
          submitTime: {
            $gt: moment().subtract(360, "hours").format(),
            $lt: moment().format(),
          },
          $sort: {
            submitTime: 1,
          },
          $limit: 100,
          include: homeworksInclude(),
        },
      })
    );

    yield put(
      actions.loadHomeworksFromApiSuccess(homeworks.data, homeworks.total)
    );
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadStreamsFromApi({ payload }) {
  try {
    const { userId } = payload;
    const streamTotalItems = yield call(() =>
      api
        .service("stream-user")
        .find({
          query: {
            userId,
          },
        })
        .then((res) => res.total)
    );

    const streamUsers = yield call(() =>
      api.service("stream-user").find({
        query: {
          userId,
          $select: ["id", "streamId", "userId"],
          $limit: streamTotalItems,
          include: teacherStreamsInclude(userId),
        },
      })
    );

		const individualLesson = yield call(() =>
			api
			.service("lesson-individual")
			.find({
				query: {
					$select: [
						"id",
						"userId",
						"teacherId",
						"courseId",
						"subjectId",
						"startAt",
						"finishAt",
						"isIndividual",
						"isHidden",
					],
					teacherId: {
						$in: [userId],
					},
				},
			})
			.then((result) => result.data)
    );

    yield put(actions.loadIndividualLessonFromApiSuccess(individualLesson));

    yield put(
      actions.loadStreamsFromApiSuccess(streamUsers.data, streamUsers.total)
    );
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadStreamAllDataFromApi({ payload }) {
  try {
    const userId = yield select(getUserId);

    const { streamUserId } = payload;

    const streamUser = yield call(() =>
      api.service("stream-user").findOne({
        query: {
          id: streamUserId,
          include: teacherStreamsAllDataInclude(userId),
        },
      })
    );

    yield put(actions.loadStreamAllDataFromApiSuccess(streamUser));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError());
  }
}

function* saveToApi({ payload }) {
  try {
    const { userId, data } = payload;

    const result = yield call(() =>
      api.service(COLLECTION_NAME).patch(userId, data)
    );

    yield put(actions.saveToApiSuccess(result));
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

function* toggleLessonHidden({ payload }) {
  try {
    const userId = yield select(getUserId);

    const { lessonId, params } = payload;

    yield call(() => api.service("lesson").patch(lessonId, params));
    yield put(actions.loadStreamsFromApi(userId));
		yield put(actions.loadNextLessonsFromApi());
  } catch (error) {
    console.log(error);
    yield put(actions.toggleLessonHiddenError(error));
  }
}

function* toggleIndividualLessonHidden({ payload }) {
  try {
    const userId = yield select(getUserId);

    const { lessonId, params } = payload;

    yield call(() => api.service("lesson-individual").patch(lessonId, params));
    yield put(actions.loadStreamsFromApi(userId));
  } catch (error) {
    console.log(error);
    yield put(actions.toggleLessonHiddenError(error));
  }
}

function* deleteStreamUser({ payload }) {
  try {
    const { streamUserId, userId } = payload;

    yield call(() =>
      api.service("stream-user").patch(streamUserId, { deleted: true })
    );
    yield put(actions.loadFromApi(userId));
  } catch (error) {
    console.log(error);
    yield put(actions.saveToApiError(error));
  }
}

function* loadOwnTeacherLessons() {
	try {
		const userId = yield select(getUserId);

		const lessonsTotalItems = (yield call(() => api.service('lesson').find({
			query: {
				teacherId: userId,
				include: nextLessonsInclude(),
				$limit: 0
			}
		}))).total;

		const lessons = yield call(() => api.service('lesson').find({
			query: {
				teacherId: userId,
				include: nextLessonsInclude(),
				$limit: lessonsTotalItems
			}
		}));

		yield put(actions.loadOwnTeacherStreams(lessons.data));
		yield put(actions.loadOwnTeacherLessonsSuccess(lessons.data));
	} catch (error) {
		console.log(error);
		yield put(actions.loadOwnTeacherLessonsError(error));
	}
}

function* loadOwnTeacherStreams({ payload }) {
  try {
		const userId = yield select(getUserId);

		const streamsIds = payload.map((ownTeacherLesson) => ownTeacherLesson.streamId);

		const query =  {
			id: {
				$in: streamsIds
			},
			$select: ["id", "num", "usersLimit", "courseId"],
			include: ownTeacherCourseInclude(userId),
		};

		const streamsCount = yield call(() => 
			api
				.service("stream")
				.find({ query: { ...query, $select: ['id'], $limit: 0 } })
				.then((res) => res.total)
		);

    const streams = yield call(() =>
      api.service("stream").find({
				query: {
					...query,
					$limit: streamsCount
				}
      })
    );

    yield put(
      actions.loadOwnTeacherStreamsSuccess(streams.data)
    );
  } catch (error) {
    console.log(error);
    yield put(actions.loadOwnTeacherStreamsError(error));
  }
}

function* deleteTeacherFromCourse({ payload: { courseId, userId } }) {
  try {
		const courseStreams = yield call(() => api.service('stream').find({
			query: {
				courseId
			},
			paginate: false
		}));

		const streamsIds = courseStreams.data.map((courseStream) => courseStream.id);

		const teacherLessonsTotalItems = (yield call(() => api.service('lesson').find({
			query: {
				streamId: {
					$in: streamsIds
				},
				teacherId: userId,
				$limit: 0
			},
		}))).total;

		const teacherLessons = yield call(() => api.service('lesson').find({
			query: {
				streamId: {
					$in: streamsIds
				},
				teacherId: userId,
				$limit: teacherLessonsTotalItems
			},
		}));

		yield all(teacherLessons.data.map(
			(teacherLesson) => call(
				() => api.service('lesson').patch(teacherLesson.id, { teacherId: null })
			)
		));

		yield put(actions.loadOwnTeacherLessons());
    yield put(
      actions.deleteTeacherFromCourseSuccess()
    );
  } catch (error) {
    console.log(error);
    yield put(actions.deleteTeacherFromCourseError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),
    takeEvery(actions.LOAD_USER_FROM_API, loadUserFromApi),
    takeEvery(actions.LOAD_STREAMS_FROM_API, loadStreamsFromApi),
    takeEvery(actions.LOAD_STREAM_ALL_DATA_FROM_API, loadStreamAllDataFromApi),
    takeEvery(actions.LOAD_NEXT_LESSONS_FROM_API, loadNextLessonsFromApi),
    takeEvery(actions.LOAD_HOMEWORKS_FROM_API, loadHomeworksFromApi),
    takeEvery(actions.SAVE_TO_API, saveToApi),
    takeEvery(actions.TOGGLE_LESSON_HIDDEN, toggleLessonHidden),
    takeEvery(
      actions.TOGGLE_INDIVIDUAL_LESSON_HIDDEN,
      toggleIndividualLessonHidden
    ),
    takeEvery(actions.DELETE_STREAM_USER, deleteStreamUser),
		takeEvery(actions.LOAD_OWN_TEACHER_LESSONS, loadOwnTeacherLessons),
		takeEvery(actions.LOAD_OWN_TEACHER_STREAMS, loadOwnTeacherStreams),
		takeEvery(actions.DELETE_TEACHER_FROM_COURSE, deleteTeacherFromCourse)
  ]);
}
