import actions from "./actions";

const initState = {
  isLoadingUser: false,
  error: null,
  user: {},
  userSubscription: {},
  nextLessons: [],
  individualLessons: [],
  streamUsers: [],
  courses: [],
	isLessonCreatedMailSended: false,
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_API:
      return {
        ...state,
        isLoadingUser: true,
        error: false,
      };
    case actions.LOAD_FROM_API_SUCCESS:
      return {
        ...state,
        isLoadingUser: false,
        user: payload.user,
        error: false,
      };
    case actions.LOAD_USER_SUBSCRIPTION_FROM_API:
      return {
        ...state,
        error: false,
      };
    case actions.LOAD_USER_SUBSCRIPTION_FROM_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userSubscription: payload.userSubscription,
        error: false,
      };
    case actions.LOAD_FROM_API_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case actions.LOAD_USER_FROM_API:
      return {
        ...state,
        isLoadingUser: true,
        error: false,
      };
    case actions.LOAD_USER_FROM_API_SUCCESS:
      return {
        ...state,
        isLoadingUser: false,
        user: payload.user,
        error: false,
      };
    case actions.LOAD_USER_FROM_API_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case actions.LOAD_STREAMS_FROM_API:
      return {
        ...state,
        error: false,
      };
    case actions.LOAD_STREAMS_FROM_API_SUCCESS:
      return {
        ...state,
        nextLessons: payload.nextLessons,
        streamUsers: payload.streamUsers,
        streamTotalItems: payload.streamTotalItems,
        error: false,
      };
    case actions.LOAD_STREAMS_FROM_API_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case actions.LOAD_COURSES_FROM_API:
      return {
        ...state,
        error: false,
      };
    case actions.LOAD_COURSES_FROM_API_SUCCESS:
      return {
        ...state,
        courses: payload.courses,
        error: false,
      };
    case actions.LOAD_COURSES_FROM_API_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case actions.LOAD_INDIVIDUAL_LESSON_FROM_API:
      return {
        ...state,
        individualLessons: payload.individualLessons,
        error: false,
      };
    case actions.SAVE_TO_API:
      return {
        ...state,
        error: false,
      };
    case actions.SAVE_TO_API_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.data,
        },
        error: false,
      };
    case actions.SAVE_TO_API_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case actions.DELETE_STREAM_USER:
      return {
        ...state,
        error: false,
      };
    case actions.DELETE_STREAM_USER_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case actions.TOGGLE_LESSON_HIDDEN:
      return {
        ...state,
        error: false,
      };
    case actions.TOGGLE_INDIVIDUAL_LESSON_HIDDEN:
      return {
        ...state,
        error: false,
      };
    case actions.TOGGLE_LESSON_HIDDEN_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    case actions.CREATE_STREAM_USER:
      return {
        ...state,
        error: false,
      };
    case actions.CREATE_STREAM_USER_ERROR:
      return {
        ...state,
        error: payload.error,
      };
		case actions.SAVE_INDIVIDUAL_LESSON_TO_API:
			return {
				...state,
				isLessonCreatedMailSended: false
			};
		case actions.SAVE_INDIVIDUAL_LESSON_TO_API_SUCCESS:
			return {
				...state,
				isLessonCreatedMailSended: true
			};
    default:
      return state;
  }
}
