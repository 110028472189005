import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import todoSagas from './todo/saga';
import chatSagas from './chat/saga';
import surveyListSagas from './surveyList/saga';
import surveyDetailSagas from './surveyDetail/saga';
import courseSaga from './course/saga';
import courseVaultSaga from './courseVault/saga';
import adminSaga from './admin/saga';
import adminVaultSaga from './adminVault/saga';
import mediaVaultSaga from './mediaVault/saga';
import userSaga from './user/saga';
import userVaultSaga from './userVault/saga';
import exerciseSaga from './exercise/saga';
import exerciseVaultSaga from './exerciseVault/saga';
import subjectSaga from './subject/saga';
import subjectVaultSaga from './subjectVault/saga';

import achievementSaga from './achievement/saga';
import achievementVaultSaga from './achievementVault/saga';
import lessonSaga from './lesson/saga';
import lessonVaultSaga from './lessonVault/saga';
import levelSaga from './level/saga';
import levelVaultSaga from './levelVault/saga';
import noteSaga from './note/saga';
import noteVaultSaga from './noteVault/saga';
import subscriptionSaga from './subscription/saga';
import subscriptionVaultSaga from './subscriptionVault/saga';
import streamSaga from './stream/saga';
import streamVaultSaga from './streamVault/saga';
import operationSaga from './operation/saga';
import operationVaultSaga from './operationVault/saga';
import teacherSaga from './teacher/saga';
import teacherVaultSaga from './teacherVault/saga';
import studentSaga from './student/saga';
import studentVaultSaga from './studentVault/saga';
import studentProfileSaga from './studentProfile/saga';
import teacherProfileSaga from './teacherProfile/saga';
import newsletterSaga from './newsletter/saga';
import newsletterVaultSaga from './newsletterVault/saga';
import registerOfPaymentsSaga from './registerOfPayments/saga';
import registerOfPaymentsVaultSaga from './registerOfPaymentsVault/saga';
import landingSettings from './landingSettings/saga';
import configuration from './configuration/saga';
import lessonChatSaga from './lessonChat/saga';
import lessonTeacherPageSaga from './lessonTeacherPage/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    todoSagas(),
    chatSagas(),
    surveyListSagas(),
    surveyDetailSagas(),
    courseSaga(),
    courseVaultSaga(),
    adminSaga(),
    adminVaultSaga(),
    mediaVaultSaga(),
    userSaga(),
    userVaultSaga(),
    exerciseSaga(),
    exerciseVaultSaga(),
    subjectSaga(),
    subjectVaultSaga(),
    achievementSaga(),
    achievementVaultSaga(),
    lessonSaga(),
    lessonVaultSaga(),
    noteSaga(),
    noteVaultSaga(),
    streamSaga(),
    streamVaultSaga(),
    lessonChatSaga(),,
    lessonTeacherPageSaga(),
    operationSaga(),
    operationVaultSaga(),
    subscriptionSaga(),
    subscriptionVaultSaga(),
    levelSaga(),
    levelVaultSaga(),
    teacherSaga(),
    teacherVaultSaga(),
    studentSaga(),
    studentVaultSaga(),
    studentProfileSaga(),
    teacherProfileSaga(),
    newsletterSaga(),
    newsletterVaultSaga(),
    registerOfPaymentsSaga(),
    registerOfPaymentsVaultSaga(),
    landingSettings(),
    configuration(),
  ]);
}
