const DOCUMENT = "TEACHER_PROFILE_";

const actions = {
  LOAD_FROM_API: `${DOCUMENT}LOAD_FROM_API`,
  LOAD_FROM_API_SUCCESS: `${DOCUMENT}LOAD_FROM_API_SUCCESS`,
  LOAD_FROM_API_ERROR: `${DOCUMENT}LOAD_FROM_API_ERROR`,

  LOAD_USER_FROM_API: `${DOCUMENT}LOAD_USER_FROM_API`,
  LOAD_USER_FROM_API_SUCCESS: `${DOCUMENT}LOAD_USER_FROM_API_SUCCESS`,
  LOAD_USER_FROM_API_ERROR: `${DOCUMENT}LOAD_USER_FROM_API_ERROR`,

  LOAD_STREAMS_FROM_API: `${DOCUMENT}LOAD_STREAMS_FROM_API`,
  LOAD_STREAMS_FROM_API_SUCCESS: `${DOCUMENT}LOAD_STREAMS_FROM_API_SUCCESS`,
  LOAD_STREAMS_FROM_API_ERROR: `${DOCUMENT}LOAD_STREAMS_FROM_API_ERROR`,

  LOAD_STREAM_ALL_DATA_FROM_API: `${DOCUMENT}LOAD_STREAM_ALL_DATA_FROM_API`,
  LOAD_STREAM_ALL_DATA_FROM_API_SUCCESS: `${DOCUMENT}LOAD_STREAM_ALL_DATA_FROM_API_SUCCESS`,

  LOAD_NEXT_LESSONS_FROM_API: `${DOCUMENT}LOAD_NEXT_LESSONS_FROM_API`,
  LOAD_NEXT_LESSONS_FROM_API_SUCCESS: `${DOCUMENT}LOAD_NEXT_LESSONS_FROM_API_SUCCESS`,

  LOAD_HOMEWORKS_FROM_API: `${DOCUMENT}LOAD_NEXT_HOMEWORKS_FROM_API`,
  LOAD_HOMEWORKS_FROM_API_SUCCESS: `${DOCUMENT}LOAD_NEXT_HOMEWORKS_FROM_API_SUCCESS`,

  SAVE_TO_API: `${DOCUMENT}SAVE_TO_API`,
  SAVE_TO_API_SUCCESS: `${DOCUMENT}SAVE_TO_API_SUCCESS`,
  SAVE_TO_API_ERROR: `${DOCUMENT}SAVE_TO_API_ERROR`,

  LOAD_COURSES_FROM_API: `${DOCUMENT}LOAD_COURSES_FROM_API`,
  LOAD_COURSES_FROM_API_SUCCESS: `${DOCUMENT}LOAD_COURSES_FROM_API_SUCCESS`,
  LOAD_COURSES_FROM_API_ERROR: `${DOCUMENT}LOAD_COURSES_FROM_API_ERROR`,

  TOGGLE_LESSON_HIDDEN: `${DOCUMENT}TOGGLE_LESSON_HIDDEN`,

  TOGGLE_INDIVIDUAL_LESSON_HIDDEN: `${DOCUMENT}TOGGLE_INDIVIDUAL_LESSON_HIDDEN`,
  TOGGLE_LESSON_HIDDEN_ERROR: `${DOCUMENT}TOGGLE_LESSON_HIDDEN_ERROR`,

  DELETE_STREAM_USER: `${DOCUMENT}DELETE_STREAM_USER`,

	LOAD_OWN_TEACHER_LESSONS: `${DOCUMENT}LOAD_OWN_TEACHER_LESSONS`,
	LOAD_OWN_TEACHER_LESSONS_SUCCESS: `${DOCUMENT}LOAD_OWN_TEACHER_LESSONS_SUCCESS`,
	LOAD_OWN_TEACHER_LESSONS_ERROR: `${DOCUMENT}LOAD_OWN_TEACHER_LESSONS_ERROR`,

	LOAD_OWN_TEACHER_STREAMS: `${DOCUMENT}LOAD_OWN_TEACHER_STREAMS`,
	LOAD_OWN_TEACHER_STREAMS_SUCCESS: `${DOCUMENT}LOAD_OWN_TEACHER_STREAMS_SUCCESS`,
	LOAD_OWN_TEACHER_STREAMS_ERROR: `${DOCUMENT}LOAD_OWN_TEACHER_STREAMS_ERROR`,

	LOAD_INDIVIDUAL_LESSON_FROM_API: `${DOCUMENT}LOAD_INDIVIDUAL_LESSON_FROM_API`,
  LOAD_INDIVIDUAL_LESSON_FROM_API_SUCCESS: `${DOCUMENT}LOAD_INDIVIDUAL_LESSON_FROM_API_SUCCESS`,
	
	DELETE_TEACHER_FROM_COURSE: `${DOCUMENT}DELETE_TEACHER_FROM_COURSE`,
	DELETE_TEACHER_FROM_COURSE_SUCCESS: `${DOCUMENT}DELETE_TEACHER_FROM_COURSE_SUCCESS`,
	DELETE_TEACHER_FROM_COURSE_ERROR: `${DOCUMENT}DELETE_TEACHER_FROM_COURSE_ERROR`,

  loadFromApi: (id) => {
    return {
      type: actions.LOAD_FROM_API,
      payload: {
        userId: id,
      },
    };
  },

  loadFromApiSuccess: (user) => ({
    type: actions.LOAD_FROM_API_SUCCESS,
    payload: {
      user,
    },
  }),

  loadFromApiError: (error) => ({
    type: actions.LOAD_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  loadUserFromApi: (id) => {
    return {
      type: actions.LOAD_USER_FROM_API,
      payload: {
        userId: id,
      },
    };
  },

  loadUserFromApiSuccess: (user) => ({
    type: actions.LOAD_USER_FROM_API_SUCCESS,
    payload: {
      user,
    },
  }),

  loadUserFromApiError: (error) => ({
    type: actions.LOAD_USER_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  loadCoursesFromApi: () => {
    return {
      type: actions.LOAD_COURSES_FROM_API,
    };
  },

  loadCoursesFromApiSuccess: (courses) => ({
    type: actions.LOAD_COURSES_FROM_API_SUCCESS,
    payload: {
      courses,
    },
  }),

  loadCoursesFromApiError: (error) => ({
    type: actions.LOAD_COURSES_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  loadStreamsFromApi: (id) => {
    return {
      type: actions.LOAD_STREAMS_FROM_API,
      payload: {
        userId: id,
      },
    };
  },

  loadStreamsFromApiSuccess: (streamUsers, streamTotalItems) => ({
    type: actions.LOAD_STREAMS_FROM_API_SUCCESS,
    payload: {
      streamUsers,
      streamTotalItems,
    },
  }),

  loadStreamsFromApiError: (error) => ({
    type: actions.LOAD_STREAMS_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  loadStreamAllDataFromApi: (id) => {
    return {
      type: actions.LOAD_STREAM_ALL_DATA_FROM_API,
      payload: {
        streamUserId: id,
      },
    };
  },

  loadStreamAllDataFromApiSuccess: (streamUser) => ({
    type: actions.LOAD_STREAM_ALL_DATA_FROM_API_SUCCESS,
    payload: {
      streamUser,
    },
  }),

  loadNextLessonsFromApi: (id) => ({
    type: actions.LOAD_NEXT_LESSONS_FROM_API,
    payload: {
      userId: id,
    },
  }),

  loadNextLessonsFromApiSuccess: (nextLessons) => ({
    type: actions.LOAD_NEXT_LESSONS_FROM_API_SUCCESS,
    payload: {
      nextLessons,
    },
  }),

  loadHomeworksFromApi: (userId) => ({
    type: actions.LOAD_HOMEWORKS_FROM_API,
    payload: {
      userId,
    },
  }),

  loadHomeworksFromApiSuccess: (nextHomeworks, totalItems) => ({
    type: actions.LOAD_HOMEWORKS_FROM_API_SUCCESS,
    payload: {
      nextHomeworks,
      totalItems,
    },
  }),

  saveToApi: (id, data) => {
    return {
      type: actions.SAVE_TO_API,
      payload: {
        userId: id,
        data,
      },
    };
  },

  saveToApiSuccess: (data) => ({
    type: actions.SAVE_TO_API_SUCCESS,
    payload: {
      data,
    },
  }),

  saveToApiError: (error) => ({
    type: actions.SAVE_TO_API_ERROR,
    payload: {
      error,
    },
  }),

  toggleLessonHidden: (lessonId, params) => {
    return {
      type: actions.TOGGLE_LESSON_HIDDEN,
      payload: {
        lessonId,
        params,
      },
    };
  },

  toggleIndividualLessonHidden: (lessonId, params) => {
    return {
      type: actions.TOGGLE_INDIVIDUAL_LESSON_HIDDEN,
      payload: {
        lessonId,
        params,
      },
    };
  },

  toggleLessonHiddenError: (error) => ({
    type: actions.TOGGLE_LESSON_HIDDEN_ERROR,
    payload: {
      error,
    },
  }),

  deleteStreamUser: (streamUserId, userId) => {
    return {
      type: actions.DELETE_STREAM_USER,
      payload: {
        streamUserId,
        userId,
      },
    };
  },

	loadOwnTeacherLessons: () => ({
		type: actions.LOAD_OWN_TEACHER_LESSONS,
	}),

	loadOwnTeacherLessonsSuccess: (lessons) => ({
		type: actions.LOAD_OWN_TEACHER_LESSONS_SUCCESS,
		payload: {
			lessons,
		}
	}),

	loadOwnTeacherLessonsError: (error) => ({
    type: actions.LOAD_OWN_TEACHER_LESSONS_ERROR,
    payload: {
      error,
    },
  }),

	loadOwnTeacherStreams: (ownTeacherLessons) => ({
		type: actions.LOAD_OWN_TEACHER_STREAMS,
		payload: ownTeacherLessons
	}),

	loadOwnTeacherStreamsSuccess: (streams) => ({
		type: actions.LOAD_OWN_TEACHER_STREAMS_SUCCESS,
		payload: {
			streams,
		}
	}),

	loadOwnTeacherStreamsError: (error) => ({
    type: actions.LOAD_OWN_TEACHER_STREAMS_ERROR,
    payload: {
      error,
    },
  }),

	loadIndividualLessonFromApi: () => ({
    type: actions.LOAD_INDIVIDUAL_LESSON_FROM_API,
  }),

  loadIndividualLessonFromApiSuccess: (individualLessons) => ({
    type: actions.LOAD_INDIVIDUAL_LESSON_FROM_API_SUCCESS,
    payload: {
      individualLessons,
    },
  }),

	deleteTeacherFromCourse: (courseId, userId) => ({
		type: actions.DELETE_TEACHER_FROM_COURSE,
		payload: { courseId, userId }
	}),

	deleteTeacherFromCourseSuccess: () => ({
		type: actions.DELETE_TEACHER_FROM_COURSE_SUCCESS,
	}),

	deleteTeacherFromCourseError: (error) => ({
    type: actions.DELETE_TEACHER_FROM_COURSE_ERROR,
    payload: {
      error,
    },
  }),
};

export default actions;
