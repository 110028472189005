import actions from "./actions";

const initState = {
  isLoading: false,
  error: null,
  landing: [],
  courses: [],
  subjects: [],
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_API:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case actions.LOAD_FROM_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        landing: payload.data,
        error: false,
      };
    case actions.LOAD_FROM_API_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case actions.LOAD_COURSES_FROM_API:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case actions.LOAD_COURSES_FROM_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        courses: payload.data,
        error: false,
      };
    case actions.LOAD_COURSES_FROM_API_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case actions.LOAD_SUBJECTS_FROM_API:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case actions.LOAD_SUBJECTS_FROM_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subjects: payload.data,
        error: false,
      };
    case actions.LOAD_SUBJECTS_FROM_API_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case actions.SAVE_TO_API:
      return {
        ...state,
        error: false,
      };
    case actions.SAVE_TO_API_SUCCESS:
      return {
        ...state,
        error: false,
      };
    case actions.SAVE_TO_API_ERROR:
      return {
        ...state,
        error: payload.error,
      };
    default:
      return state;
  }
}
