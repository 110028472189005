import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import api from './realtime';
import actions from './actions';
import _ from 'lodash';

const getUser = (state) => state.authUser.user;

const messagesLimit = 30

function* loadFromApi({ payload }) {
  try {
    const user = yield select(getUser);

    const {
      lessonId
    } = payload

    const conversations = []
    
    const lesson = yield call(() => api.service('lesson').get(lessonId, {
			query: { $getIndividual: true }
		}));

    const usersIds = !lesson.isIndividual ? (yield call(() => api.service('stream-user').find({
      query: {
        $or: [
					{ streamId: lesson.streamId },
					{ userId: lesson.teacherId },
				],
        $limit: 50,
      }
    }).then(res => [
			..._.map(res.data, value => value.userId),
			lesson.teacherId
		]))) : [lesson.userId, lesson.teacherId];

    const users = yield call(() => api.service('user').find({
      query: {
        id: {
          $in: usersIds
        },
        $limit: usersIds?.length || 2,
        $select: ['id', 'firstname', 'lastname', 'role', 'avatarId']
      }
    }))

    const defaultMessagesParams = {
      $or: {
				lessonId,
				lessonIndividualId: lessonId
			},
      $limit: messagesLimit,
      $sort: {
        sendedAt: -1
      }
    }

		if (!lesson.isIndividual) {
      console.log('test')
			const generalConversationMessages = yield call(() => api.service('chat-message').find({
				query: {
					...defaultMessagesParams,
					receiverId: null,
				}
			}))

			const generalConversation = {
				id: 'general',
				user: null,
				messages: generalConversationMessages.data.reverse(),
				hasEarlyMessages: generalConversationMessages.data.length < generalConversationMessages.total
			}
	
			conversations.push(generalConversation)
		}

    if (user.role === 'student') {
      const teacher = _.find(users.data, user => user.role === 'teacher')

      if (teacher) {
        const conversationUsers = [teacher.id, user.id]

        const teacherConversationMessages = yield call(() => api.service('chat-message').find({
          query: {
            ...defaultMessagesParams,
            receiverId: {
              $in: conversationUsers
            },
          }
        }))

        const conversationWithTeacher = {
          id: teacher.id,
          user: teacher,
          messages: teacherConversationMessages.data.reverse(),
          hasEarlyMessages: teacherConversationMessages.data.length < teacherConversationMessages.total
        }

        conversations.push(conversationWithTeacher)
      }
    } else if (user.role === 'teacher') {
      const students = _.filter(users.data, student => student.id !== user.id)

      for (const student of students) {
        const conversationUsers = [user.id, student.id]

        const studentConversationMessages = yield call(() => api.service('chat-message').find({
          query: {
            ...defaultMessagesParams,
            receiverId: {
              $in: conversationUsers
            },
          }
        }))

        const conversationWithStudent = {
          id: student.id,
          user: student,
          messages: studentConversationMessages.data.reverse(),
          hasEarlyMessages: studentConversationMessages.data.length < studentConversationMessages.total
        }

        conversations.push(conversationWithStudent)
      }
    }

    yield put(actions.loadFromApiSuccess(conversations, users.data, user.id, lessonId));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

function* loadEarlyMessagesFromApi({ payload }) {
  try {
    const {
      sendedAt,
      lessonId,
      senderId,
      receiverId,
      conversationId
    } = payload

    const messagesData = yield call(() => api.service('chat-message').find({
      query: {
        $or: {
					lessonId,
					lessonIndividualId: lessonId
				},
        sendedAt: {
          $lt: sendedAt
        },
        $limit: messagesLimit,
        $sort: {
          sendedAt: -1
        }
      }
    }))

    const messages = messagesData.data.reverse()

    yield put(actions.loadEarlyMessagesFromApiSuccess(messages, messagesData.total, conversationId));
  } catch (error) {
    console.log(error)
    yield put(actions.loadFromApiError(error));
  }
}

function* saveToApi({ payload }) {
  try {
    console.log(payload)
    const test = yield call(() => api.service('chat-message').create(payload.messageData))
    console.log(test)
  } catch (error) {
    console.log(error)
    yield put(actions.saveToApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_API, loadFromApi),
    takeEvery(actions.LOAD_EARLY_MESSAGES_FROM_API, loadEarlyMessagesFromApi),
    takeEvery(actions.SAVE_TO_API, saveToApi),
  ]);
}
