import { all, takeEvery, put, call } from 'redux-saga/effects';
import { NotificationManager } from 'components/common/react-notifications';
import api from './realtime';
import actions from './actions';

const COLLECTION_NAME = 'message-template'; // change your collection

function* getItem({ payload }) {
  try {
    const messageTemplate = yield call(() =>
      api.service(COLLECTION_NAME).get(payload.id)
    );
    const messageDistribution = yield call(() =>
      api.service('message-distribution').findOne({
        query: {
          templateId: messageTemplate.id,
        },
      })
    );

    messageTemplate.messageDistribution = messageDistribution;

    yield put(actions.getItemSuccess(messageTemplate));
  } catch (error) {
    NotificationManager.error(
      error.message,
      error.name,
      10000,
      null,
      null,
      'filled'
    );
    console.log(error);
    yield put(actions.getItemError());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ITEM, getItem)]);
}
