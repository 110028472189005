import actions from './actions';

import _ from 'lodash'

const initState = {
  currentUserId: null,
  currentLessonId: null,
  isLoading: false,
  isSavingMessage: false,
  error: null,
  conversations: [],
  users: []
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_API:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case actions.LOAD_FROM_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        conversations: payload.conversations,
        users: payload.users,
        currentUserId: payload.currentUserId,
        currentLessonId: payload.lessonId,
        error: false,
      };
    case actions.LOAD_EARLY_MESSAGES_FROM_API:
      return {
        ...state,
        error: false,
      };
    case actions.LOAD_EARLY_MESSAGES_FROM_API_SUCCESS:
      const currentConversationIndex = _.findIndex(
        state.conversations, conversation => conversation.id === (payload.converastionId)
      )

      state.conversations[currentConversationIndex].messages =
        [...payload.messages, ...state.conversations[currentConversationIndex].messages]

      if (payload.messages.length === payload.total) {
        state.conversations[currentConversationIndex].hasEarlyMessages = false
      }

      return {
        ...state,
        error: false,
      };
    case actions.LOAD_FROM_API_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case actions.MESSAGES_MARK_AS_READ:
      const сonversationIndex = _.findIndex(
        state.conversations, conversation => conversation.id === payload.conversationId
      )

      _.forEach(state.conversations[сonversationIndex].messages, message => {
        delete message.isNewMessage
      })

      return {
        ...state,
      };
    case actions.SAVE_TO_API:
      return {
        ...state,
        isSavingMessage: true,
        error: false,
      };
    case actions.SAVE_TO_API_SUCCESS:
      const receiverId = payload.message.receiverId || 'general'
      const senderId = payload.message.senderId

      const conversations = state.conversations

      if (senderId === state.currentUserId) {
        const currentConversationIndex = _.findIndex(
          state.conversations, conversation => conversation.id === receiverId
        )

        conversations[currentConversationIndex].messages.push(payload.message)
      } else {
        const currentConversationIndex = _.findIndex(
          state.conversations, conversation => {
            return receiverId === 'general'
              ? conversation.id === receiverId
              : conversation.id === senderId
          }
        )

        const message = payload.message
        message.isNewMessage = true
        conversations[currentConversationIndex].messages.push(payload.message)
      }

      return {
        ...state,
        isSavingMessage: false,
        conversations,
        error: false,
      };
    case actions.SAVE_TO_API_ERROR:
      return {
        ...state,
        isSavingMessage: false,
        error: payload.error,
      };
    default:
      return state;
  }
}
