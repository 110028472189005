export const streamsInclude = (itemId) => {
  return [
    {
      model: "lesson",
      as: "lessons",
      required: false,
      attributes: ["id", "startAt"],
      where: {
        deleted: false,
      },
      sortBy: [["startAt", "ASC"]],
      include: [
        {
          model: "exercise",
          as: "exercise",
          required: false,
          where: {
            deleted: false,
          },
          attributes: ["name", "duration"],
        },
      ],
    },
    {
      model: "stream_user",
      as: "streamUsers",
      required: false,
      where: {
        deleted: false,
      },
      streamId: itemId,
      attributes: ["id", "userId"],
    },
  ];
};
