import { all, takeEvery, put, call } from 'redux-saga/effects';
import api from 'api';
import actions from './actions';

const COLLECTION_CONFIGURATION = 'configuration'; // change your collection

function* loadFromApi() {
  try {
    const params = {
      query: {
        $limit: 100,
      },
    };

    const { data } = yield call(() =>
      api.service(COLLECTION_CONFIGURATION).find(params)
    );

    yield put(
      actions.loadFromApiSuccess({
        data,
      })
    );
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromApiError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_FROM_API, loadFromApi)]);
}
