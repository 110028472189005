import api from 'api';

// eslint-disable-next-line import/no-cycle
import store from 'redux/store';

import actions from './actions';

api.service('note').on('created', (item) => {
  store.dispatch(actions.getItemSuccess(item));
});

api.service('note').on('patched', (item) => {
  store.dispatch(actions.getItemSuccess(item));
});

api.service('note').on('updated', (item) => {
  store.dispatch(actions.getItemSuccess(item));
});

api.service('note').on('removed', (item) => {
  store.dispatch(actions.getItemSuccess({ ...item, deleted: true }));
});

export default api;
