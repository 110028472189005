const DOCUMENT = "LANDINGSETTINGS _";

const actions = {
  LOAD_FROM_API: `${DOCUMENT}LOAD_FROM_API`,
  LOAD_FROM_API_SUCCESS: `${DOCUMENT}LOAD_FROM_API_SUCCESS`,
  LOAD_FROM_API_ERROR: `${DOCUMENT}LOAD_FROM_API_ERROR`,

  LOAD_COURSES_FROM_API: `${DOCUMENT}LOAD_COURSES_FROM_API`,
  LOAD_COURSES_FROM_API_SUCCESS: `${DOCUMENT}LOAD_COURSES_FROM_API_SUCCESS`,
  LOAD_COURSES_FROM_API_ERROR: `${DOCUMENT}LOAD_COURSES_FROM_API_ERROR`,

  LOAD_SUBJECTS_FROM_API: `${DOCUMENT}LOAD_SUBJECTS_FROM_API`,
  LOAD_SUBJECTS_FROM_API_SUCCESS: `${DOCUMENT}LOAD_SUBJECTS_FROM_API_SUCCESS`,
  LOAD_SUBJECTS_FROM_API_ERROR: `${DOCUMENT}LOAD_SUBJECTS_FROM_API_ERROR`,

  SAVE_TO_API: `${DOCUMENT}SAVE_TO_API`,
  SAVE_TO_API_SUCCESS: `${DOCUMENT}SAVE_TO_API_SUCCESS`,
  SAVE_TO_API_ERROR: `${DOCUMENT}SAVE_TO_API_ERROR`,

  loadFromApi: () => {
    return {
      type: actions.LOAD_FROM_API,
    };
  },

  loadFromApiSuccess: (data) => ({
    type: actions.LOAD_FROM_API_SUCCESS,
    payload: {
      data,
    },
  }),

  loadFromApiError: (error) => ({
    type: actions.LOAD_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  loadCoursesFromApi: () => {
    return {
      type: actions.LOAD_COURSES_FROM_API,
    };
  },

  loadCoursesFromApiSuccess: (data) => ({
    type: actions.LOAD_COURSES_FROM_API_SUCCESS,
    payload: {
      data,
    },
  }),

  loadCoursesFromApiError: (error) => ({
    type: actions.LOAD_COURSES_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  loadSubjectsFromApi: () => {
    return {
      type: actions.LOAD_SUBJECTS_FROM_API,
    };
  },

  loadSubjectsFromApiSuccess: (data) => ({
    type: actions.LOAD_SUBJECTS_FROM_API_SUCCESS,
    payload: {
      data,
    },
  }),

  loadSubjectsFromApiError: (error) => ({
    type: actions.LOAD_SUBJECTS_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  saveToApi: (item) => ({
    type: actions.SAVE_TO_API,
    payload: {
      item,
    },
  }),

  saveToApiSuccess: (item) => ({
    type: actions.SAVE_TO_API_SUCCESS,
    payload: {
      item,
    },
  }),

  saveToApiError: (error) => ({
    type: actions.SAVE_TO_API_ERROR,
    payload: {
      error,
    },
  }),
};

export default actions;
