const DOCUMENT = 'LESSON_TEACHER_PAGE_';

const actions = {
  LOAD_FROM_API: `${DOCUMENT}LOAD_FROM_API`,
  LOAD_FROM_API_SUCCESS: `${DOCUMENT}LOAD_FROM_API_SUCCESS`,
  LOAD_FROM_API_ERROR: `${DOCUMENT}LOAD_FROM_API_ERROR`,

  LOAD_TESTS_FROM_API: `${DOCUMENT}LOAD_TESTS_FROM_API`,
  LOAD_TESTS_FROM_API_SUCCESS: `${DOCUMENT}LOAD_TESTS_FROM_API_SUCCESS`,

  LOAD_HOMEWORKS_FROM_API: `${DOCUMENT}LOAD_HOMEWORKS_FROM_API`,
  LOAD_HOMEWORKS_FROM_API_SUCCESS: `${DOCUMENT}LOAD_HOMEWORKS_FROM_API_SUCCESS`,

  SAVE_TO_API: `${DOCUMENT}SAVE_TO_API`,
  SAVE_TO_API_SUCCESS: `${DOCUMENT}SAVE_TO_API_SUCCESS`,
  SAVE_TO_API_ERROR: `${DOCUMENT}SAVE_TO_API_ERROR`,

  SAVE_HOMEWORK_TO_API: `${DOCUMENT}SAVE_HOMEWORK_TO_API`,
  SAVE_HOMEWORK_TO_API_SUCCESS: `${DOCUMENT}SAVE_HOMEWORK_TO_API_SUCCESS`,

  SAVE_TEST_TO_API: `${DOCUMENT}SAVE_TEST_TO_API`,
  SAVE_TEST_TO_API_SUCCESS: `${DOCUMENT}SAVE_TEST_TO_API_SUCCESS`,

  SET_TESTS_PAGE: `${DOCUMENT}SET_TESTS_PAGE`,
  SET_HOMEWORKS_PAGE: `${DOCUMENT}SET_HOMEWORKS_PAGE`,
 

  loadFromApi: (lessonId) => {
    return {
      type: actions.LOAD_FROM_API,
      payload: {
        lessonId,
      },
    };
  },

  loadFromApiSuccess: (lesson, streamLessons, students, hasAccessToLesson) => ({
    type: actions.LOAD_FROM_API_SUCCESS,
    payload: {
      lesson,
      streamLessons,
      students,
      hasAccessToLesson,
    },
  }),

  loadFromApiError: (error) => ({
    type: actions.LOAD_FROM_API_ERROR,
    payload: {
      error,
    },
  }),

  loadTestsFromApi: () => {
    return {
      type: actions.LOAD_TESTS_FROM_API,
      payload: {},
    };
  },

  loadTestsFromApiSuccess: (tests, totalItems) => ({
    type: actions.LOAD_TESTS_FROM_API_SUCCESS,
    payload: {
      tests,
      totalItems,
    },
  }),

  loadHomeworksFromApi: () => {
    return {
      type: actions.LOAD_HOMEWORKS_FROM_API,
      payload: {},
    };
  },

  loadHomeworksFromApiSuccess: (homeworks, totalItems) => ({
    type: actions.LOAD_HOMEWORKS_FROM_API_SUCCESS,
    payload: {
      homeworks,
      totalItems,
    },
  }),

  saveToApi: (data) => {
    return {
      type: actions.SAVE_TO_API,
      payload: {
        data,
      },
    };
  },

  saveToApiSuccess: (lesson) => ({
    type: actions.SAVE_TO_API_SUCCESS,
    payload: {
      lesson,
    },
  }),

  saveToApiError: (error) => ({
    type: actions.SAVE_TO_API_ERROR,
    payload: {
      error,
    },
  }),

  saveHomeworkToApi: (homeworkId, data) => {
    return {
      type: actions.SAVE_HOMEWORK_TO_API,
      payload: {
        homeworkId,
        data,
      },
    };
  },

  saveHomeworkToApiSuccess: (homework) => ({
    type: actions.SAVE_HOMEWORK_TO_API_SUCCESS,
    payload: {
      homework,
    },
  }),

  saveTestToApi: (testId, data) => {
    return {
      type: actions.SAVE_TEST_TO_API,
      payload: {
        testId,
        data,
      },
    };
  },

  saveTestToApiSuccess: (test) => ({
    type: actions.SAVE_TEST_TO_API_SUCCESS,
    payload: {
      test,
    },
  }),

  setTestsPage: (page) => ({
    type: actions.SET_TESTS_PAGE,
    payload: {
      page,
    },
  }),

  setHomeworksPage: (page) => ({
    type: actions.SET_HOMEWORKS_PAGE,
    payload: {
      page,
    },
  }),

};

export default actions;
